import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

const SubGroup = () => {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [stat, setStat] = useState("");
  const [file, setfile] = useState("");
  const [uId, setuId] = useState("");
  const [sub, setsub] = useState("");

  useEffect(() => {
    setCode(Math.floor(Math.random() * 965225825) + 15860 * 5);
  }, []);

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      axios
        .post("https://test.eliteyez.com/admin/Subcategory", {
          code: code,
          name: name,
          uId: uId,
          stat: stat,
        })
        .then((res) => {
          alert("Success Upload Image");
          setCode(Math.floor(Math.random() * 965225825) + 15860 * 5);
        });
    } catch (err) {
      console.log(err);
      alert("Error Upload Image");
    }
  };

  const [dataAll, setDataAll] = useState([]);
  const [filterAll, setFilterAll] = useState(dataAll);

  useEffect(() => {
    const getAllProducts = async () => {
      const res = await fetch("https://test.eliteyez.com/admin/SubGroupList");
      setDataAll(await res.clone().json());
      setFilterAll(await res.json());
    };
    getAllProducts();
  }, []);

  const [GroupList, setGroupList] = useState([]);
  const [GroupListR, setGroupListR] = useState(GroupList);

  useEffect(() => {
    const axiosTest = async () => {
      let res = await fetch("https://test.eliteyez.com/admin/GrouopList");
      setGroupList(await res.clone().json());
      setGroupListR(await res.json());
    };
    axiosTest();
  }, []);

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/SubCategoryDelete/${id}`)
      .then((response) => {
        setDataAll((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Success");
      });
  };

  return (
    <>
      <main id="main" class="main mt-1">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Suest</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="EnterName"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="uId"
                  onChange={(e) => setuId(e.target.value)}
                  className="form-control"
                >
                  <option value={"020s"}>Select Group</option>
                  {GroupListR.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-6">
                <select
                  name="stat"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setStat(e.target.value)}
                >
                  <option value={"01"}>IsActive</option>
                  <option>True</option>
                  <option>False</option>
                </select>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-info"
                  onClick={handlePayment}
                >
                  Add Sub Service
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">SubService</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Service</th>
                        <th>Stat</th>
                      </tr>
                    </thead>

                    {filterAll.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.code}</td>
                            <td>{item.name}</td>
                            <td>{item.uId}</td>
                            <td>{item.stat}</td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default SubGroup;
