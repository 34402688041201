import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CreateSelectType from "./CreateSelectType";

export const Shopping = () => {
  const [dataAll, setDataAll] = useState([]);
  const [filterAll, setFilterAll] = useState(dataAll);

  useEffect(() => {
    const axiosTest = async () => {
      let res = await fetch("https://test.eliteyez.com/admin/ListCategory");
      setDataAll(await res.clone().json());
      setFilterAll(await res.json());
    };
    axiosTest();
  }, []);

  const [subG, setSubg] = useState([]);
  const [subGV, setSubgV] = useState(subG);

  useEffect(() => {
    const axiosTest = async () => {
      let res = await fetch("https://test.eliteyez.com/admin/SubGroupList");
      setSubg(await res.clone().json());
      setSubgV(await res.json());
    };
    axiosTest();
  }, []);

  const updateShowHide = async (id, stat) => {
    handleupdateTrue(id);
  };

  const handleupdateTrue = async (id) => {
    try {
      await axios
        .put(`https://test.eliteyez.com/admin/CategoryUpdateTrue/${id}`, {
          stat: "False",
        })
        .then(() => {
          alert("Success Update");
        });
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteGroupShop/${id}`)
      .then((response) => {
        setDataAll((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Success");
      });
  };

  const filterSubGroup = async (id) => {
    // فیلتر واحدها بر اساس  انتخاب مجتمع
    try {
      const res = await fetch(
        `https://test.eliteyez.com/admin/SubGroupListFilter/${id}`
      );
      setSubg(await res.json());
    } catch (err) {}
  };

  const getAllListSubCategory = async () => {
    const res = await fetch("https://test.eliteyez.com/admin/SubGroupList");
    setSubg(await res.json());
  };

  /*   useEffect(() => {
    const getAllProducts = async () => {
      const res = await fetch(
        `https://test.eliteyez.com/panelUsersRes/kindeFilter/${currentUser.meli}`
      );
      setDataAll(await res.clone().json());
      setFilterAll(await res.json());
    };
    getAllProducts();
  }, []); */

  const [subGR, setSubgR] = useState([]);
  const [subGB, setSubgB] = useState(subGR);

  const AlltestSeven = async (id) => {
    try {
      const res = await fetch(
        `https://test.eliteyez.com/admin/filterListSevenCate/${id}`
      );
      setSubgR(await res.clone().json());
      setSubgB(await res.json());
    } catch (err) {
      console.log(err);
    }
  };

  const [listProduct, setListProduct] = useState([]);
  const [listProductR, setListProductR] = useState(listProduct);

  useEffect(() => {
    const axiosTest = async () => {
      let res = await fetch("https://test.eliteyez.com/admin/listItemAll");
      setListProduct(await res.clone().json());
      setListProductR(await res.json());
    };
    axiosTest();
  });

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteItemAll/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Product Row");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <hr />
        <Link to={"/panelAdmin/Create-Product"}>
          <button className="btn btn-primary">CreateServices</button>
        </Link>
        <p></p>
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                {filterAll.map((item) => {
                  const { id, stat, name, code, img, date } = item;
                  return (
                    <div class="col-xxl-4 col-md-4">
                      <div class="card info-card sales-card">
                        <div class="filter">
                          <a class="icon" href="#" data-bs-toggle="dropdown">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <li>
                              <Link to={"/panelAdmin/GroupAll"}>
                                <a class="dropdown-item" href="#">
                                  <img
                                    src="/img/plus.png"
                                    height={"20px"}
                                    width={"20px"}
                                  />{" "}
                                  Add-Services
                                </a>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/panelAdmin/GroupAll"}>
                                <a class="dropdown-item" href="#">
                                  <img
                                    src="/img/editA.png"
                                    height={"20px"}
                                    width={"20px"}
                                  />{" "}
                                  Edit-Services
                                </a>
                              </Link>
                            </li>
                            <li onClick={() => handleDelete(id)}>
                              <a class="dropdown-item" href="#">
                                <img
                                  src="/img/rem.png"
                                  height={"20px"}
                                  width={"20px"}
                                />{" "}
                                Remove-Services
                              </a>
                            </li>
                            <li onClick={() => updateShowHide(id, stat)}>
                              <a class="dropdown-item" href="#">
                                <img
                                  src="/img/show.png"
                                  height={"20px"}
                                  width={"20px"}
                                />{" "}
                                Show/Hide
                              </a>
                            </li>
                            <li
                              onClick={() => filterSubGroup(name)}
                              name="name"
                            >
                              <a class="dropdown-item" href="#">
                                <img
                                  src="/img/group.png"
                                  height={"20px"}
                                  width={"20px"}
                                />{" "}
                                Filter-SubServices
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="card-body">
                          <h5 class="card-title">{item.name}</h5>
                          <div class="d-flex align-items-center">
                            <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <Link
                                to="/panelAdmin/createTypeSh"
                                state={{ id, code, name, img, stat }}
                              >
                                <img
                                  src={item.img}
                                  width={"50px"}
                                  height={"50px"}
                                  className="mt-2"
                                  style={{ cursor: "pointer" }}
                                />
                              </Link>
                            </div>
                            <div class="ps-3">
                              <h6>{item.stat}</h6>
                              <hr />
                              <span class="text-success small pt-1 fw-bold">
                                {item.code}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div class="col-12">
                  <div class="card top-selling overflow-auto">
                    <div class="card-body pb-0">
                      <p></p>
                      <img
                        src="/img/unf.png"
                        width={"30px"}
                        height={"30px"}
                        onClick={getAllListSubCategory}
                        alt="UnFilter"
                        title="UnFilter List"
                        style={{ cursor: "pointer" }}
                      />
                      <hr />
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Stat</th>
                            <th>SubServices</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subGV.map((item) => {
                            const { id, sub } = item;
                            return (
                              <tr>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{item.stat}</td>
                                <td>{item.uId}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
