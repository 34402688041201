import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

export const Payment = () => {
  const [tokenPay, setTokenpay] = useState("");
  const [namePay, setNamePay] = useState("");
  const [statPay, setStatPay] = useState("");
  const [merchendID, setMerchendID] = useState("");
  const [terminalID, setTerminalID] = useState("");
  const [keyID, setKeyID] = useState("");
  const [amount, setAmount] = useState("");
  const [descs, setDescs] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    setTokenpay(Math.floor(Math.random() * 2050358 + 2380));
  }, []);

  const [paymentType, setPaymentType] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/PaymentType"
      );
      setPaymentType(response.data);
    };
    axiosTest();
  }, []);

  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/listAllPayment"
      );
      setPaymentList(response.data);
    };
    axiosTest();
  }, []);

  const handelSubmitPayment = async () => {
    try {
      const data = await axios.post(
        "https://test.eliteyez.com/admin/addPayment",
        {
          tokenPay: tokenPay,
          namePay: namePay,
          statPay: statPay,
          merchendID: merchendID,
          terminalID: terminalID,
          keyID: keyID,
          amount: amount,
          descs: descs,
          type: type,
        }
      );
      alert("Success Add Payment");
    } catch (err) {
      alert("Error Submit Payment Try Again");
    }
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deletePayment/${id}`)
      .then((response) => {
        setPaymentList((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Success");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">PaymentForm</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  type="email"
                  class="form-control"
                  placeholder="NamePayment"
                  name="namePay"
                  onChange={(e) => setNamePay(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="statPay"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setStatPay(e.target.value)}
                >
                  <option value={"230"}>IsActive</option>
                  <option>True</option>
                  <option>False</option>
                </select>
              </div>
              <div class="col-md-6">
                <input
                  className="form-control"
                  placeholder="Enter merchend-Id"
                  name="merchendID"
                  onChange={(e) => setMerchendID(e.target.value)}
                />
              </div>
              <div class="col-12">
                <input
                  className="form-control"
                  placeholder="Enter terminal-Id"
                  name="terminalID"
                  onChange={(e) => setTerminalID(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <input
                  className="form-control"
                  placeholder="Enter key-Id"
                  name="keyID"
                  onChange={(e) => setKeyID(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <input
                  className="form-control"
                  placeholder="Enter amount"
                  name="amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div class="col-md-4">
                <select
                  name="sevenStat"
                  onChange={(e) => setType(e.target.value)}
                  className="form-control"
                >
                  <option value={"ad2cs0"}>Select PaymentType</option>
                  {paymentType.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-12">
                <textarea
                  rows="8"
                  cols="50"
                  placeholder="Message"
                  style={{ padding: "0px" }}
                  className="form-control mt-3"
                  name="descs"
                  onChange={(e) => setDescs(e.target.value)}
                ></textarea>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={handelSubmitPayment}
                >
                  Add Payment
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">PaymentList</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Active</th>
                        <th>TypePay</th>
                      </tr>
                    </thead>

                    {paymentList.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.namePay}</td>
                            <td>{item.statPay}</td>
                            <td>{item.type}</td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
