import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const Patent = () => {
  const [detailes, setdetailes] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      try {
        const resT = await fetch("https://test.eliteyez.com/patOff");
        setdetailes(await resT.json());
      } catch (error) {}
    };
    getAllPaymentType();
  }, []);

  return (
    <>
      <main id="main" class="main">
        <div className="row">
          <section className="section dashboard">
            <div className="col-lg-12">
              <div className="row">
                {/* Sales Card */}
                {detailes.map((cat) => {
                  return (
                    <div className="col-md-4">
                      <div
                        className="card info-card sales-card"
                        style={{ height: "300px" }}
                      >
                        <div className="card-body">
                          <h5 className="card-title">{cat.title}</h5>
                          <hr />
                          <div className="d-flex align-items-center">
                            <div>
                              <p>{cat.descs}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
