import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

export const JupiterNews = () => {
  const [newsCode, setnewsCode] = useState("");
  const [newsTitle, setnewsTitle] = useState("");
  const [newsDesc, setnewsDesc] = useState("");
  const [newsStat, setnewsStat] = useState("");
  const [newsType, setnewsType] = useState("");

  useEffect(() => {
    setnewsCode(Math.floor(Math.random() * 2050358 + 2380));
  }, []);

  const [paymentList, setPaymentList] = useState([]);
  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch("https://test.eliteyez.com/admin/listNews");
      setPaymentList(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const handelSubmitPayment = async () => {
    try {
      const data = await axios.post("https://test.eliteyez.com/admin/addNews", {
        newsCode: newsCode,
        newsTitle: newsTitle,
        newsStat: newsStat,
        newsDesc: newsDesc,
        newsType: "1",
      });
      alert("Success Add News");
    } catch (err) {
      alert("Error Submit News Try Again");
    }
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteNews/${id}`)
      .then((response) => {
        setPaymentList((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete News Success");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Add-News</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  className="form-control"
                  placeholder="Title"
                  name="newsTitle"
                  onChange={(e) => setnewsTitle(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="newsStat"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setnewsStat(e.target.value)}
                >
                  <option value={"230"}>IsActive</option>
                  <option>True</option>
                  <option>False</option>
                </select>
              </div>
              <div class="col-md-12">
                <textarea
                  rows="8"
                  cols="50"
                  placeholder="Message"
                  style={{ padding: "0px" }}
                  className="form-control"
                  name="newsDesc"
                  onChange={(e) => setnewsDesc(e.target.value)}
                ></textarea>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  onClick={handelSubmitPayment}
                >
                  Add News
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">PaymentList</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Active</th>
                        <th scope="col">Message</th>
                      </tr>
                    </thead>

                    {paymentList.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.newsTitle}</td>
                            <td>{item.newsStat}</td>
                            <td>{item.newsDesc}</td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                alt=""
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
