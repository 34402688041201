import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextUser } from "../context/UserContext";

export const ResivedMsg = () => {
  const [listProduct, setListProduct] = useState([]);
  const { currentUser } = useContext(AuthContextUser);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        `https://test.eliteyez.com/getAllComplatReqUser/${currentUser.email}`
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  return (
    <>
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    Resolution Center latest claim and IP Infringements
                  </h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Code</th>
                        <th>Stat</th>
                      </tr>
                    </thead>

                    {listProduct.map((item, index) => {
                      const { id, userid, unitRF } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{item.val3}</td>
                            <td>{item.val1}</td>
                            <td>{item.val2}</td>
                            <td>{item.val4}</td>
                            <td>
                              <textarea
                                className="form-control"
                                rows={3}
                                value={item.msg}
                                readOnly
                              ></textarea>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={15000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </section>
      </main>
    </>
  );
};
