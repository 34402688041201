import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { saveAs } from "file-saver";

export const Crawler = () => {
  const [crwal, setcrwal] = useState([]);
  const [urlOne, setUrlOne] = useState("");
  const [urlOne2, setUrlOne2] = useState("");
  const url = "https://www.profesia.sk";

  const crawlerSearch = async () => {
    startTimer();
    let response = await axios.post(
      "https://test.eliteyez.com/crl/imageCrawler"
    );
    setcrwal(response.data);
  };

  const [seconds, setSeconds] = useState(0);

  const startTimer = () => {
    setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
  };

  const currentCount = seconds;

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Crawler Image And Video</h5>
                <div className="row col-sm-12">
                  <div class="col-md-3">
                    <input
                      className="form-control"
                      placeholder="WebSite"
                      name="newsTitle"
                      value={"https://www.profesia.sk"}
                      style={{ textAlign: "center" }}
                      onChange={(e) => setUrlOne(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <input
                      className="form-control"
                      placeholder="WebSite"
                      value={"https://www.profesia.sk/praca/"}
                      name="newsTitle"
                      style={{ textAlign: "center" }}
                      onChange={(e) => setUrlOne2(e.target.value)}
                    />
                  </div>
                  <div class="col-md-2">
                    <button className="btn btn-success" onClick={crawlerSearch}>
                      Start Crawler
                    </button>
                  </div>
                  <div class="col-md-3">
                    {currentCount >= 60 ? (
                      (clearInterval(currentCount), (<p>CrawlerEnd : 0</p>))
                    ) : (
                      <div>CrawlerStart :{currentCount}</div>
                    )}
                  </div>
                  <div className="container mt-5">
                    <div className="row">
                      <div className="card col-sm-2">
                        <a href="/logs/Ad page.json" className="ms-2" download>
                          Report All Page
                        </a>
                      </div>
                      <div className="card col-sm-2">
                        <a
                          href="/logs/finalErrors.json"
                          className="ms-2"
                          download
                        >
                          FinalError
                        </a>
                      </div>
                      <div className="card col-sm-2">
                        <a href="/logs/images.json" className="ms-2" download>
                          Report All Image
                        </a>
                      </div>
                      <div className="card col-sm-2">
                        <a href="/logs/log.json" className="ms-2" download>
                          Report All Log
                        </a>
                      </div>
                      <div className="card col-sm-2">
                        <a href="/logs/phone.json" className="ms-2" download>
                          Report All Phone
                        </a>
                      </div>
                      <div className="card col-sm-2">
                        <a href="/logs/title.json" className="ms-2" download>
                          Report All Title
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
