import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

export const ListCertificate = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        `https://test.eliteyez.com/admin/listUserManagerTow/${currentUser.userid}`
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <table class="table datatable">
            <thead>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Company</th>
                <th>Title</th>
                <th>Date</th>
                <th>View Certificate</th>
              </tr>
            </thead>
            {listProduct.map((item, index) => {
              const { id } = item;
              return (
                <tbody key={item.id}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.code}</td>
                    <td>{item.company}</td>
                    <td>{item.title}</td>
                    <td>{item.date}</td>
                    <td>
                      <Link
                        to="/UserPanel/Certificate"
                        state={{
                          id,
                        }}
                      >
                        <img
                          src="/img/sur.png"
                          height={"30ox"}
                          width={"30px"}
                          style={{ cursor: "pointer" }}
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
