import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextUser } from "../context/UserContext";

export const Dasheboard = () => {

  const match = localStorage.getItem('match')
  const asset = localStorage.getItem('asset')

  return (
    <>
<main id="main" class="main">
     <div className="row">
     <section className="section dashboard">

    <div className="col-lg-12">
      <div className="row">
        {/* Sales Card */}
        <div className="col-md-6">
          <div className="card info-card sales-card">
             <div className="card-body">
              <h5 className="card-title">
              Matches
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-check-circle" />
                </div>
                <div className="ps-3">
                { match === null || match === '' ? (
                  <h6>0</h6>
                ):(
                  <h6>{match}</h6>
                )
                }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Sales Card */}
        {/* Revenue Card */}
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card revenue-card">
            <div className="card-body">
              <h5 className="card-title">
              Uploaded Assets
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-view-list" />
                </div>
                <div className="ps-3">
                { asset === null || asset === '' ? (
                  <h6>2</h6>
                ):(
                  <h6>{asset}</h6>
                )
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    </div>


 <div class="col-xl-12">

<div class="card">
  <div class="card-body pt-3">
    
    <ul class="nav nav-tabs nav-tabs-bordered">

      <li class="nav-item">
        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Membership</button>
      </li>
    </ul>
    <div class="tab-content pt-2">

      <div class="tab-pane fade show active profile-overview" id="profile-overview">
      <img src="/img/2e.png" width={"900px"} />

      </div>

      <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
      <img src="/img/1e.png" width={"900px"} />

      </div>
    </div>
  </div>
</div>
</div>
      </main>
    </>
  );
};
