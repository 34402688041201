import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

export const RequestComplaint = () => {
  const { currentUser } = useContext(AuthContextUser);

  // title	date	msg	urle	img	stat	type	uId	code	unitRF
  const location = useLocation();
  const propsData = location.state;

  const [code, setcode] = useState("");
  const [title, settitle] = useState("");
  const [msg, setmsg] = useState("");
  const [date, setdate] = useState(new Date());
  const [uId, setUid] = useState("");
  const [urle, seturle] = useState("");
  const [unitRF, setunitRF] = useState("");
  const [file, setfile] = useState("");
  const [userid, setuuserid] = useState("");

  const [dataAll, setDataAll] = useState([]);
  const [filterAll, setFilterAll] = useState(dataAll);
  const [cate, setCate] = useState([]);
  const [filterAllT, setFilterAllT] = useState([]);


  useEffect(() => {
    setcode(Math.floor(Math.random() * 240) + 8640 * 208);
  }, []);

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const inserCompliate = async (e) => {
/*     e.preventDefault();
    try {
      if (!file) {
        toast.warning("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("code", code);
        formdata.append("uId", uId);
        formdata.append("title", title);
        formdata.append("date", date);
        formdata.append("unitRF", unitRF);
        formdata.append("urle", urle);
        formdata.append("msg", msg);
        formdata.append("userid", currentUser.email);
        axios
          .post("https://test.eliteyez.com/admin/complateInsert", formdata)
          .then((res) => {
            toast.success("Create Certificate - Success");
          });
      }
    } catch (err) {
      console.log(err);
      toast.error("Create Certificate - Error");
    } */

      toast.success(`Your claim has been submitted. Refrence No : ` + code)
  };

  const [detailes, setdetailes] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch(
        `https://test.eliteyez.com/getAallCompainte/${currentUser.email}`
      );
      setdetailes(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const [cateSeven, setCateSeven] = useState([]);

  const handelDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/deleteComplateAll/${id}`)
      .then((response) => {
        setCateSeven((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  useEffect(() => {
    const getAllProductsRFw = async () => {
      const res = await fetch("https://test.eliteyez.com/filterOne");
      setCate(await res.clone().json());
    };
    getAllProductsRFw();
  }, []);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(`https://test.eliteyez.com/filterTwo/${id}`);
      setFilterAllT(await res.json());
    } catch (err) {}
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Claim - Report Unauthorized Use</h5>
              <form class="row g-3">
                <div class="col-md-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="UserName"
                    value={currentUser.email}
                    readOnly
                    onChange={(e) => setuuserid(e.target.value)}
                  />
                </div>
{/*                 <div class="col-md-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div> */}
                <div class="col-xs-12 col-sm-3">
                <input
                    type="email"
                    class="form-control"
                    placeholder="url"
                    value={'WEB PROTECTION'}
                    readOnly
                  />
                </div>

                <div class="col-xs-12 col-sm-3">
                <input
                    type="email"
                    class="form-control"
                    placeholder="url"
                    value={'Image'}
                    readOnly
                  />
                </div>

                <div class="col-md-4">
                { propsData === null || propsData === '' ? (
                  <input
                    type="email"
                    class="form-control"
                    placeholder="url"
                    onChange={(e) => seturle(e.target.value)}
                    readOnly
                  />
                ):(
                  <input
                    type="email"
                    class="form-control"
                    placeholder="url"
                    value={propsData.link}
                    onChange={(e) => seturle(e.target.value)}
                    readOnly
                  />
                )
                }
                </div>

                <div class="col-md-4">
                  <input
                    type="text"
                    readOnly
                    class="form-control"
                    placeholder="Title"
                    value={date.toDateString()}
                  />
                </div>

{/*                 <div className="col col-sm-4">
                  <input
                    type="file"
                    class="form-control"
                    required
                    id="floatingName"
                    name="file"
                    onChange={handleFile}
                  />
                </div> */}

                <div class="col-md-12">
                  <textarea
                    rows={"5"}
                    class="form-control"
                    placeholder="Message"
                    onChange={(e) => setmsg(e.target.value)}
                  />
                </div>
              </form>
              <p></p>
              <button className="btn btn-primary" onClick={inserCompliate}>
                Submit your claim
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Claim Overview</h5>
            <table class="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>msg</th>
                  <th>Services</th>
                  <th>SubServices</th>
                  <th>Date</th>
                  <th>stat</th>
                </tr>
              </thead>
              {detailes.map((item, index) => {
                const { id } = item;
                return (
                  <tbody key={item.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{item.msg}</td>
                      <td>{item.uId}</td>
                      <td>{item.unitRF}</td>
                      <td>{item.date}</td>
                      <td>{item.stat}</td>
                      <td>
                        <img
                          src="/img/del.png"
                          height={"30ox"}
                          width={"30px"}
                          onClick={() => handelDelete(id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
