import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../src/assets/css/style.css";
import "bootstrap/dist/js/bootstrap.js";
import { AuthcontexProvider } from "./context/authContext";
import { AuthcontexProviderUser } from "./context/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthcontexProviderUser>
      <AuthcontexProvider>
        <App />
      </AuthcontexProvider>
    </AuthcontexProviderUser>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();
