import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";

export const Flow = () => {
  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/flowrequestAll"
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteReqAll/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  const [est, setest] = useState("");

  const updateStat = async (userID) => {
    await axios
      .put(`https://test.eliteyez.com/admin/updateAllRequ/${userID}`, {
        stat: est,
      })
      .then(() => {
        toast.success("Success Register");
      })
      .catch(() => {
        toast.error("Error Register");
      });
  };

  const downloadImageT = (image, userid) => {
    try {
      saveAs(image, "Download.rar"); // Put your image url here.
    } catch (err) {
      toast.error("فایلی وجود ندارد");
    }
  };

  return (
    <>
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Requests User</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>UserId</th>
                        <th>Title</th>
                        <th>Msg</th>
                        <th>Services</th>
                        <th>SubServices</th>
                        <th>stat</th>
                        <th>ChnageStat</th>
                        <th>Approve</th>
                        <th>Download</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    {listProduct.map((item, index) => {
                      const { id, userid, unitRF, img } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{item.userid}</td>
                            <td>{item.title}</td>
                            <td>{item.msg}</td>
                            <td>{item.uId}</td>
                            <td>{item.unitRF}</td>
                            <td>{item.stat}</td>
                            <td>
                              <select
                                name="val1"
                                id="selce"
                                className="form-control"
                                onChange={(e) => setest(e.target.value)}
                              >
                                <option value={"Select"}>SelectType</option>
                                <option value={"confirmation"}>
                                  confirmation
                                </option>
                                <option value={"disapproval"}>
                                  disapproval
                                </option>
                              </select>
                            </td>
                            <td>
                              <img
                                src="/img/act.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Approve"
                                alt=""
                                onClick={() => updateStat(id)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/download.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Approve"
                                alt=""
                                onClick={() => downloadImageT(img)}
                              />
                            </td>

                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                alt=""
                                onClick={() => handelDeleteProduct(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={15000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </section>
      </main>
    </>
  );
};
