import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const UserManagerR = () => {
  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/listUserManager"
      );
      setListProduct(response.data);
    };
    axiosTest();
  });

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteUserManager/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  const [file, setfile] = useState("");
  const [userid, setuserid] = useState("");
  const [nameuser, setnameuser] = useState("");
  const [email, setemail] = useState("");
  const [stat, setstat] = useState("");
  const [meta, setmeta] = useState("");
  const [refral, setrefral] = useState("");
  const [password, ssetpassword] = useState("");

  useEffect(() => {
    setuserid(Math.floor(Math.random() * 965225825) + 15860 * 5);
  }, []);

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        alert("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("userid", userid);
        formdata.append("nameuser", nameuser);
        formdata.append("email", email);
        formdata.append("stat", stat);
        formdata.append("meta", meta);
        formdata.append("refral", refral);
        formdata.append("password", password);

        axios
          .post("https://test.eliteyez.com/admin/AddUsers", formdata)
          .then((res) => {
            alert("Success Upload Image");
            setuserid(Math.floor(Math.random() * 96525) + 1560 * 5);
          });
      }
    } catch (err) {
      console.log(err);
      alert("Error Upload Image");
    }
  };

  const [showA, setShowA] = useState(false);
  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const handelGetMetaCode = (id, tpy) => {
    setmeta(tpy);
    handleShowA(true);
  };

  const [showR, setShowR] = useState(false);
  const handleCloseR = () => setShowR(false);
  const handleShowR = () => setShowR(true);

  const handelGetrefral = (id, Ref) => {
    setrefral(Ref);
    handleShowR(true);
  };

  const [showE, setShowE] = useState(false);
  const handleCloseE = () => setShowE(false);
  const handleShowE = () => setShowE(true);

  const [ide, setide] = useState("");

  const editForce = (
    id,
    uImg,
    uName,
    uEmail,
    uStat,
    uMeta,
    Urefral,
    uPassword
  ) => {
    setide(id);
    setfile(uImg);
    setnameuser(uName);
    setemail(uEmail);
    setstat(uStat);
    setmeta(uMeta);
    setrefral(Urefral);
    ssetpassword(uPassword);
    handleShowE(true);
  };

  const handelUpdate = async () => {
    axios.put(`https://test.eliteyez.com/admin/UpdateAllUsers/${ide}`, {
      nameuser: nameuser,
      email: email,
      stat: stat,
      meta: meta,
      refral: refral,
      password: password,
    });
    setShowE(false);
  };

  const [showU, setShowU] = useState(false);
  const handleCloseU = () => setShowU(false);
  const handleShowU = () => setShowU(true);

  const handelGetIp = (id, UserID) => {
    handleShowU(true);
  };

  const [showIM, setShowIM] = useState(false);
  const handleCloseIM = () => setShowIM(false);
  const handleShowIM = () => setShowIM(true);

  const [itde, setidte] = useState("");

  const updateImage = (id) => {
    setidte(id);
    handleShowIM(true);
  };

  const updateImageUser = async () => {
    const formdata = new FormData();
    formdata.append("image", file);
    axios
      .put(`https://test.eliteyez.com/admin/UpdateUsersImage/${itde}`, formdata)
      .then((res) => {});
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">AddUser</h5>
            <form class="row g-3">
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="file"
                    class="form-control"
                    required
                    id="floatingName"
                    name="file"
                    onChange={handleFile}
                  />
                  <label for="floatingName">Image</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    required
                    id="floatingEmail"
                    value={userid}
                    readOnly
                    placeholder="Your USerID"
                    onChange={(e) => setuserid(e.target.value)}
                  />
                  <label for="floatingEmail">UserId</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingPassword"
                    required
                    placeholder="Your Name"
                    onChange={(e) => setnameuser(e.target.value)}
                  />
                  <label for="floatingPassword">Name</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingName"
                    required
                    placeholder="Your Email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <label for="floatingName">Email</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating mb-3">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    required
                    aria-label="State"
                    onChange={(e) => setstat(e.target.value)}
                  >
                    <option>Active</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                  </select>
                  <label for="floatingSelect">State</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingName"
                    placeholder="Your Meta"
                    onChange={(e) => setmeta(e.target.value)}
                  />
                  <label for="floatingName">Your Meta</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingName"
                    placeholder="Your Referral"
                    onChange={(e) => setrefral(e.target.value)}
                  />
                  <label for="floatingName">Your Referral</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <input
                    type="password"
                    class="form-control"
                    required
                    id="floatingName"
                    placeholder="Your Name"
                    onChange={(e) => ssetpassword(e.target.value)}
                  />
                  <label for="floatingName">Your Password</label>
                </div>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={handlePayment}
                >
                  AddUser
                </button>
              </div>
            </form>
          </div>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Sort And Search</h5>
                  <hr />

                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Avatar</th>
                        <th>UserId</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Stat</th>
                        <th>Meta</th>
                        <th>Referral</th>
                        <th>Diamond</th>
                        <th>ip</th>
                      </tr>
                    </thead>
                    {listProduct.map((item, index) => {
                      const {
                        id,
                        img,
                        userid,
                        nameuser,
                        email,
                        stat,
                        meta,
                        refral,
                        password,
                      } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={item.img}
                                width={"30px"}
                                height={"30px"}
                                onClick={() => updateImage(id)}
                              />
                            </td>
                            <td>{item.userid}</td>
                            <td>{item.nameuser}</td>
                            <td>{item.email}</td>
                            <td>{item.stat}</td>
                            <td>
                              <img
                                src="/img/metamask.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                onClick={() => handelGetMetaCode(id, meta)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/ref.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                onClick={() => handelGetrefral(id, refral)}
                              />
                            </td>
                            <td>{item.diamond}</td>
                            <td>
                              <img
                                src="/img/ip.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                onClick={() => handelGetIp(id, userid)}
                              />
                            </td>

                            <td>
                              <div class="col-xxl-4 col-md-4">
                                <div class="card info-card sales-card">
                                  <div class="filter">
                                    <a
                                      class="icon"
                                      href="#"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <img
                                            src="/img/viewPay.png"
                                            height={"20px"}
                                            width={"20px"}
                                          />
                                          Transaction
                                        </a>
                                      </li>
                                      <li
                                        onClick={() =>
                                          editForce(
                                            id,
                                            img,
                                            nameuser,
                                            email,
                                            stat,
                                            meta,
                                            refral,
                                            password
                                          )
                                        }
                                      >
                                        <a class="dropdown-item" href="#">
                                          <img
                                            src="/img/editA.png"
                                            height={"20px"}
                                            width={"20px"}
                                          />{" "}
                                          ForceEdit
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          <img
                                            src="/img/show.png"
                                            height={"20px"}
                                            width={"20px"}
                                          />{" "}
                                          Show/Hide
                                        </a>
                                      </li>
                                      <li
                                        onClick={() => handelDeleteProduct(id)}
                                      >
                                        <a class="dropdown-item" href="#">
                                          <img
                                            src="/img/rem.png"
                                            height={"20px"}
                                            width={"20px"}
                                          />{" "}
                                          DeleteRow
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={showA} onHide={handleCloseA} className="mt-2" centered>
        <Modal.Header>Meta-Code</Modal.Header>
        <Modal.Body>
          <input
            type="text"
            name="meta"
            className="form-control"
            maxLength={80}
            value={meta}
            style={{ textAlign: "center" }}
            onChange={(e) => setmeta(e.target.value)}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showR} onHide={handleCloseR} className="mt-2" centered>
        <Modal.Header>Referral</Modal.Header>
        <Modal.Body>
          <input
            type="text"
            name="refral"
            className="form-control"
            maxLength={80}
            value={refral}
            style={{ textAlign: "center" }}
            onChange={(e) => setrefral(e.target.value)}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showE}
        onHide={handleCloseE}
        className="mt-2"
        centered
        size="lg"
      >
        <Modal.Header>ForceEdit</Modal.Header>
        <Modal.Body>
          <form class="row g-3">
            {/*              <div class="col-md-4">
                  <div class="form-floating">
                    <input type="file" class="form-control"  required id="floatingName" name="file" onChange={handleFile}  />
                    <label for="floatingName">Image</label>
                  </div>
                </div> */}
            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  required
                  id="floatingEmail"
                  value={userid}
                  readOnly
                  placeholder="Your USerID"
                  onChange={(e) => setuserid(e.target.value)}
                />
                <label for="floatingEmail">UserId</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="floatingPassword"
                  required
                  placeholder="Your Name"
                  value={nameuser}
                  onChange={(e) => setnameuser(e.target.value)}
                />
                <label for="floatingPassword">Name</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="email"
                  class="form-control"
                  id="floatingName"
                  required
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <label for="floatingName">Email</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <select
                  class="form-select"
                  id="floatingSelect"
                  required
                  aria-label="State"
                  value={stat}
                  onChange={(e) => setstat(e.target.value)}
                >
                  <option>Active</option>
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
                <label for="floatingSelect">State</label>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="floatingName"
                  placeholder="Your Meta"
                  value={meta}
                  onChange={(e) => setmeta(e.target.value)}
                />
                <label for="floatingName">Your Meta</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="floatingName"
                  placeholder="Your Referral"
                  value={refral}
                  onChange={(e) => setrefral(e.target.value)}
                />
                <label for="floatingName">Your Referral</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  required
                  id="floatingName"
                  placeholder="Your Name"
                  value={password}
                  onChange={(e) => ssetpassword(e.target.value)}
                />
                <label for="floatingName">Your Password</label>
              </div>
            </div>
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-success"
                onClick={handelUpdate}
              >
                Update Value
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showU} onHide={handleCloseU} className="mt-2" centered>
        <Modal.Header>Ip And Log</Modal.Header>
        <Modal.Body>
          <input
            type="text"
            name="ip"
            className="form-control"
            maxLength={80}
            readOnly
            style={{ textAlign: "center" }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showIM} onHide={handleCloseIM} className="mt-2" centered>
        <Modal.Header>UpdateImage</Modal.Header>
        <Modal.Body>
          <input
            type="file"
            name="img"
            className="form-control"
            style={{ textAlign: "center" }}
            onChange={handleFile}
          />
          <hr />
          <div style={{ textAlign: "center" }}>
            <button className="btn btn-success" onClick={updateImageUser}>
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
