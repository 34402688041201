import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export const UserRegister = () => {
  const [file, setfile] = useState("");
  const [userid, setuserid] = useState("");
  const [nameuser, setnameuser] = useState("");
  const [email, setemail] = useState("");
  const [stat, setstat] = useState("");
  const [meta, setmeta] = useState("");
  const [refral, setrefral] = useState("");
  const [password, ssetpassword] = useState("");

  useEffect(() => {
    setuserid(Math.floor(Math.random() * 965225825) + 15860 * 5);
  }, []);

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      axios
        .post("https://test.eliteyez.com/admin/AddUsers", {
          userid: userid,
          nameuser: nameuser,
          email: email,
          password: password,
        })
        .then((res) => {
          toast.success("Success - Record");
          setuserid(Math.floor(Math.random() * 96525) + 1560 * 5);
        })
        .catch((Err) => {
          toast.error("Error Duplicate Record");
        });
    } catch (err) {
      toast.error("Error - UnRegister");
    }
  };

  return (
    <>
      <main>
        <div class="container">
          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="d-flex justify-content-center py-4">
                    <a
                      href="index.html"
                      class="logo d-flex align-items-center w-auto"
                    >
                      <img src="/img/logo192.png" alt="" />
                      <span class="d-none d-lg-block">RegisterUser</span>
                    </a>
                  </div>

                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">
                          Create an Account
                        </h5>
                        <p class="text-center small">
                          Enter your personal details to create account
                        </p>
                      </div>
                      <div class="col-12">
                        <label for="yourName" class="form-label">
                          UserID
                        </label>
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          id="yourName"
                          value={userid}
                          readOnly
                          onChange={(e) => setuserid(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please, enter your UserID!
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="yourEmail" class="form-label">
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          id="yourEmail"
                          required
                          onChange={(e) => setemail(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please enter a valid Email adddress!
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="yourUsername" class="form-label">
                          Username
                        </label>
                        <div class="input-group has-validation">
                          <span class="input-group-text" id="inputGroupPrepend">
                            @
                          </span>
                          <input
                            type="text"
                            name="username"
                            class="form-control"
                            id="yourUsername"
                            required
                            onChange={(e) => setnameuser(e.target.value)}
                          />
                          <div class="invalid-feedback">
                            Please choose a username.
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="yourPassword" class="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          class="form-control"
                          id="yourPassword"
                          required
                          onChange={(e) => ssetpassword(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please enter your password!
                        </div>
                      </div>
                      <br />
                      <div class="col-12">
                        <button
                          class="btn btn-primary w-100"
                          onClick={handlePayment}
                        >
                          Create Account
                        </button>
                      </div>
                      <br />
                      <div class="col-12">
                        <p class="small mb-0">
                          Already have an account?{" "}
                          <Link to={"/UsersLogin"}>Log in</Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="credits">
                    Designed by <a href="#">Eliteyez</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
