import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "jalali-moment";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const LoginUser = () => {
  const [inputs, setInput] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const { login } = useContext(AuthContextUser);

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const [ipAddress, setIPAddress] = useState("");
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => response.json())
      .then((data) => {
        setIPAddress(data.IPv4);
        setCountry(data.country_name);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
      })
      .catch((error) => console.log(error));
  }, []);

  const submitLog = async () => {
    try {
      await axios.post("https://test.eliteyez.com/panelUsersRes/LogRcp", {
        email: inputs.email,
        date: moment(new Date(), "YYYY/MM/DD")
          .locale("en")
          .format("YYYY/MM/DD"),
        ip: ipAddress,
        stat: country,
        latx: latitude,
        laty: longitude,
      });
    } catch (err) {
      toast.error("Error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!inputs.email || !inputs.password) {
        toast.warning("Username or password cannot be empty");
      } else {
        const user_captcha =
          document.getElementById("user_captcha_input").value;
        if (validateCaptcha(user_captcha) === true) {
          await login(inputs);
          navigate("/UserPanel/Dasheboard");
          submitLog();
        } else {
          toast.error("Error Captcha");
        }
      }
    } catch (err) {
      toast.error("Username or password is wrong");
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(5, "white", "red", "numbers");
  }, []);

  const [showa, setShowa] = useState(false);
  const handleClosea = () => setShowa(false);
  const handleShowa = () => setShowa(true);

  const [meli, setmeli] = useState("");

  const resetPassword = async () => {
    try {
      axios.post("https://test.eliteyez.com/panelUsersRes/userResetPassword", {
        meli: meli,
      });
      toast.success("در صورت تایید کد ملی رمز عبور برای شما پیامک خواهد شد");
    } catch (err) {
      toast.error("خطا در تغییر رمز عبور");
    }
  };

  const [showb, setShowb] = useState(false);
  const handleCloseb = () => setShowb(false);
  const handleShowb = () => setShowb(true);

  return (
    <>
      <br />
      <br />
      <div class="container mt-5">
        <section class="section register">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    class="logo d-flex align-items-center w-auto"
                  >
                    <img src="./img/logo192.png" alt="" />
                    <span class="d-none d-lg-block">UserLogin</span>
                  </a>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="pt-4 pb-2">
                      <div style={{ textAlign: "center" }}>
                        <img src="./img/logo192.png" alt="" width={"50px"} />
                      </div>
                    </div>
                    <form class="row g-3 needs-validation mt-2" novalidate>
                      <div class="col-12">
                        <label>Email</label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            name="email"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            style={{ textAlign: "center" }}
                            required
                            onChange={handleChange}
                          />
                          <div class="invalid-feedback">
                            Please enter your username.
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          class="form-control"
                          id="yourPassword"
                          placeholder="Password"
                          style={{ textAlign: "center" }}
                          required
                          onChange={handleChange}
                        />
                        <div class="invalid-feedback">
                          Please enter your password!
                        </div>
                      </div>
                      <div class="col-12">
                        <label>Captcha</label>
                        <input
                          type="text"
                          id="user_captcha_input"
                          style={{ textAlign: "center" }}
                          maxLength={"5"}
                          class="form-control"
                          name="user_captcha_input"
                          placeholder="Captcha"
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <LoadCanvasTemplateNoReload />
                      </div>
                      <div className="col-12">
                        <p class="small mb-0">
                          Don't have account?{" "}
                          <Link to={"/UserRegister"}>Create an account</Link>
                        </p>
                      </div>
                      <div class="col-12">
                        <button
                          class="btn btn-primary w-100"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Login
                        </button>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="/img/help.png"
                            width={"50px"}
                            height={"50px"}
                            alt="عکس"
                            title="Help"
                            className="mt-5"
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src="/img/cha.png"
                            width={"50px"}
                            height={"50px"}
                            alt="عکس"
                            className="mt-5 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleShowa}
                          />
                          <img
                            src="/img/support.png"
                            width={"50px"}
                            height={"50px"}
                            alt="عکس"
                            title="Support"
                            className="mt-5 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleShowb}
                          />
                          <img
                            src="/img/App.png"
                            width={"50px"}
                            height={"50px"}
                            alt="عکس"
                            title="AppAndroid"
                            className="mt-5 ms-2"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="credits">
                  Designed by <a href="#">Eliteyez</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <Modal show={showa} onHide={handleClosea} className="mt-5">
        <Modal.Body>
          <h6 style={{ textAlign: "center" }}>Password-Reovery</h6>
          <br />
          <input
            type="text"
            name="meli"
            className="form-control"
            style={{ textAlign: "center" }}
            placeholder="Email"
            onChange={(e) => setmeli(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button variant="primary" onClick={resetPassword}>
            Email-Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showb} onHide={handleCloseb} className="mt-5">
        <Modal.Body>
          <h6 style={{ textAlign: "center" }}>Support</h6>
          <br />
          <input
            type="text"
            name="name"
            className="form-control"
            maxLength={10}
            style={{ textAlign: "center" }}
            placeholder="Email"
          />
          <br />
          <input
            type="text"
            name="meli"
            className="form-control"
            maxLength={11}
            style={{ textAlign: "center" }}
            placeholder="Title"
          />
          <br />
          <textarea
            rows={5}
            type="text"
            name="tel"
            className="form-control"
            maxLength={4000}
            placeholder="Desc"
          />
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button variant="info">Add Message</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
