import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BarLoader,ClipLoader,BeatLoader,CircleLoader,MoonLoader,HashLoader   } from 'react-spinners';
import { Link, useNavigate } from "react-router-dom";

export const Infringement = () => {

    const { currentUser } = useContext(AuthContextUser);

    const [detailes, setdetailes] = useState([]);

    const [bol,setBol] = useState(false)
   
 const getAllProductsRFw = async () => {
          try {
            setBol(true)
            timerTwo()
            localStorage.setItem('match',12)
            localStorage.setItem('asset',3)
            const res = await fetch("https://test.eliteyez.com/filterTwo");
            setdetailes(await res.clone().json());
          } catch (e) {}
};

const timerTwo = () => {
        setTimeout(() => {
            setBol(false)
        }, 5000);
}

return (
    <>
         <main id="main" class="main">
        <div className="row">
          <section className="section dashboard">
            <div style={{textAlign:'center',cursor:'pointer'}}>
              <img src='/img/up.png' height={'80px'} width={'80px'} onClick={getAllProductsRFw}/>
            </div>
            <p></p>
            <div style={{textAlign:'center'}}>
            <BeatLoader  color="purple" height={5} width={100} loading={bol} />
            </div>
            <br />
            <div class="card">
          <div class="card-body">
            <table class="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>source</th>
                  <th>link</th>
                  <th>image</th>
                  <th>claim</th>
                </tr>
              </thead>
              {detailes.map((item, index) => {
                const { id,source,link } = item;
                return (
                  <tbody key={item.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.source}</td>
                      <td>{item.link}</td>
                      <td><img src={item.img} height={'50px'} width={'50px'} /></td>
                      <td><Link to="/UserPanel/RequestComplaint" state={{
                                  source,
                                  link}}><button className="btn btn-info">Claim</button></Link></td>
                                  
                      </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
              </section>
              </div>
              </main>
    </>
  )
}
