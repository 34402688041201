import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";

export const ComplateFlow = () => {
  const [listProduct, setListProduct] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/Allcompalte"
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/AllDeleteComplate/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  const [est, setest] = useState("");

  const updateStat = async (userID) => {
    await axios
      .put(`https://test.eliteyez.com/admin/updateComplate/${userID}`, {
        stat: est,
      })
      .then(() => {
        toast.success("Success Register");
      })
      .catch(() => {
        toast.error("Error Register");
      });
  };

  const [val1, setval1] = useState();
  const [val2, setval2] = useState();
  const [val3, setval3] = useState();
  const [val4, setval4] = useState();

  const messageType = (userid, code, title, stat) => {
    setval1(userid);
    setval2(code);
    setval3(title);
    setval4(stat);
    handleShow(true);
  };

  const [mee, setMess] = useState("");

  const insertMessage = async (arv) => {
    try {
      axios.post("https://test.eliteyez.com/admin/ComplateReqRang", {
        val1: val1,
        val2: val2,
        val3: val3,
        val4: val4,
        msg: arv,
        date: new Date(),
      });
      handleClose(true);
      toast.success("Register Success");
    } catch (Err) {
      toast.error("Error Type Message");
    }
  };

  return (
    <>
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Requests User</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>UserId</th>
                        <th>Title</th>
                        <th>Msg</th>
                        <th>Services</th>
                        <th>SubServices</th>
                        <th>stat</th>
                        <th>ChnageStat</th>
                        <th>ApproveStat</th>
                        <th>Answer</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    {listProduct.map((item, index) => {
                      const { id, userid, unitRF, code, title, stat } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{item.userid}</td>
                            <td>{item.title}</td>
                            <td>{item.msg}</td>
                            <td>{item.uId}</td>
                            <td>{item.unitRF}</td>
                            <td>{item.stat}</td>
                            <td>
                              <select
                                name="val1"
                                id="selce"
                                className="form-control"
                                onChange={(e) => setest(e.target.value)}
                              >
                                <option value={"Accepted"}>Accepted</option>
                                <option value={"Reviewed"}>Reviewed</option>
                                <option value={"Pending"}>Pending</option>
                                <option value={"Reviewed"}>suspended</option>
                              </select>
                            </td>
                            <td>
                              <img
                                src="/img/act.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Approve"
                                alt=""
                                onClick={() => updateStat(code)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/editA.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Approve"
                                alt=""
                                onClick={() =>
                                  messageType(userid, code, title, stat)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                alt=""
                                onClick={() => handelDeleteProduct(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={15000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <h6 style={{ textAlign: "center" }}>Message</h6>
              <textarea
                rows={5}
                className="form-control"
                onChange={(e) => setMess(e.target.value)}
              ></textarea>
              <p></p>
              <button
                className="btn btn-success"
                placeholder="Message Type"
                onClick={() => insertMessage(mee)}
              >
                Send Message
              </button>
            </Modal.Body>
          </Modal>
        </section>
      </main>
    </>
  );
};
