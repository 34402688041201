import React,{useContext, useState,useEffect} from "react";
import { AuthContextUser } from '../context/UserContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from  'axios'

export const ChangePassword = () => {

  const { currentUser } = useContext(AuthContextUser);

return (

 <main id="main" class="main">
<section class="section">
  <div class="row">

  <div class="card">
            <div class="card-body">
              <h5 class="card-title">ChangePassword</h5>
              <form>
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">OldPassword </label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputText" />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword3" class="col-sm-2 col-form-label">NewPassword</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword" />
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">ChangePassword</button>
     
                </div>
              </form>

            </div>
          </div>

    </div>

    <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
</section>

</main>
  
  );
};

