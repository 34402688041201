import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

export const CertificateAdmin = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [code, setcode] = useState("");
  const [company, setcompany] = useState("");
  const [title, settitle] = useState("");
  const [date, setdate] = useState("");
  const [exporter, setexporter] = useState("");
  const [username, setusername] = useState("");
  const [msg, setmsg] = useState("");
  const [barcode, setbarcode] = useState("");

  useEffect(() => {
    setcode(Math.floor(Math.random() * 82580) + 58200 * 3);
  }, []);

  const [file, setfile] = useState("");

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const insertcirt = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        toast.warning("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("code", code);
        formdata.append("company", company);
        formdata.append("title", title);
        formdata.append("date", date);
        formdata.append("exporter", exporter);
        formdata.append("username", username);
        formdata.append("msg", msg);
        formdata.append("barcode", barcode);

        axios
          .post("https://test.eliteyez.com/admin/InsertAll", formdata)
          .then((res) => {
            toast.success("Create Certificate - Success");
          });
      }
    } catch (err) {
      console.log(err);
      toast.error("Create Certificate - Error");
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [pay, setPay] = useState([]);

  const downloadImage = async (refralCode) => {
    const res = await fetch("https://test.eliteyez.com/admin/listUserManager");
    setPay(await res.json());
    handleShow(true);
  };

  const [sel, setsel] = useState("");

  const setVlauee = (userid) => {
    setsel(userid);
    setusername(userid);
    handleClose(true);
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body" style={{ textAlign: "center" }}>
              <p></p>
              <img src="/img/logo192.png" height={"100px"} />
              <p></p>
              <div className="container">
                <div className="row">
                  <div className="col col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="NameCompany"
                      onChange={(e) => setcompany(e.target.value)}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      onChange={(e) => settitle(e.target.value)}
                    />
                  </div>

                  <div className="col col-sm-4">
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setdate(e.target.value)}
                    />
                  </div>
                  <p></p>
                  <div className="col col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Exporter"
                      onChange={(e) => setexporter(e.target.value)}
                    />
                  </div>
                  <div className="col col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="UsrName"
                      value={sel}
                      onChange={(e) => setusername(e.target.value)}
                    />
                  </div>
                  <div className="col col-sm-1">
                    <img
                      src="/img/sel.png"
                      title="SelectUser"
                      height={"40px"}
                      style={{ cursor: "pointer" }}
                      onClick={downloadImage}
                    />
                  </div>
                  <p></p>
                  <div className="col col-sm-4">
                    <input
                      type="file"
                      class="form-control"
                      required
                      id="floatingName"
                      name="file"
                      onChange={handleFile}
                    />
                  </div>
                  <div className="col col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Barcode Name"
                      onChange={(e) => setbarcode(e.target.value)}
                    />
                  </div>

                  <p></p>
                  <div className="col col-sm-12">
                    <textarea
                      rows={"5"}
                      className="form-control"
                      placeholder="Message"
                      onChange={(e) => setmsg(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <p></p>
              <button className="btn btn-success" onClick={insertcirt}>
                CreateCertificate
              </button>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <h6 style={{ textAlign: "center" }}>UserList</h6>
            <p></p>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>UserId</th>
                    <th>Nameuser</th>
                    <th>Email</th>
                    <th>Select</th>
                  </tr>
                </thead>
                {pay.map((item) => {
                  const { id, email, userid } = item;
                  return (
                    <tbody
                      class="table-group-divider table-divider-color"
                      key={item.id}
                    >
                      <tr>
                        <td>{item.userid}</td>
                        <td>{item.nameuser}</td>
                        <td>{item.email}</td>
                        <td>
                          <img
                            src="/img/pkus.png"
                            height={"30px"}
                            style={{ cursor: "pointer" }}
                            onClick={() => setVlauee(userid)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </main>
  );
};
