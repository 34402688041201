import React,{useState,useEffect} from 'react'
import axios from 'axios'

export const ApiFind = () => {


const [mainList, setMainList] = useState([]);

/* useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://test.eliteyez.com/admin/apiFinde");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]); */

  const [name,setName] = useState('')
  const [loc,setLoc] = useState('')

  const  FindeImageLenz = async() => {
    const val1 = "Austin, TX, Texas, United States"
    const res = await fetch(`https://test.eliteyez.com/admin/apiFinde/$${name}`);
    
    // const res = await fetch(`https://test.eliteyez.com/admin/apiFinde/$${name}&${loc}`);
    // `https://test.eliteyez.com/panelUsersRes/ListPayment/${uId}&${val1}`
    setMainList(await res.json());
  }

const search = async() =>{
  const val1 = "Austin, TX, Texas, United States"
  const res = await fetch('https://test.eliteyez.com/admin/search');
  setMainList(await res.json());
}

return (
<>
<main id="main" class="main">
      <section class="section">
        <div class="row">
      
          
          <div class="col-md-4"><input type='text' className='form-control' placeholder='Name' onChange={(e) => setName(e.target.value)} /></div>
          <div class="col-md-4"><input type='text' className='form-control' placeholder='Location' onChange={(e) => setLoc(e.target.value)} /></div>
          <div class="col-md-4"><input type='text' className='form-control' /></div>
         
          
          <p></p>
          <button className='btn btn-info col col-sm-3' onClick={FindeImageLenz}>Search Image</button>
          <button className='btn btn-info col col-sm-3 ms-2' onClick={search}>Search</button>
          
          <p></p>
          <hr />
          <table class="table datatable">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Image</th>
                <th>Source</th>
                <th>Icon/Image</th>
                <th></th>
              </tr>
            </thead>
            {mainList.map((item, index) => {
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td><img src={item.thumbnail} /></td>
                          <td>{item.source}</td>
                          <td><img src={item.favicon} /></td>
                        </tr>
                      </tbody>
                    );
                  })}

          </table>
        </div>
      </section>
    </main>

    </>
  )
}

