import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

export const TypePayment = () => {
  const [code, setcode] = useState("");
  const [name, setname] = useState("");
  const [stat, setstat] = useState("");

  const [paymentList, setPaymentList] = useState([]);
  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch("https://test.eliteyez.com/admin/PaymentType");
      setPaymentList(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const handelSubmitPayment = async () => {
    try {
      await axios.post("https://test.eliteyez.com/admin/insertPaymentType", {
        code: code,
        name: name,
        stat: "True",
      });
      alert("Success Add News");
    } catch (err) {
      alert("Error Submit News Try Again");
    }
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deletePaymentType/${id}`)
      .then((response) => {
        setPaymentList((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete News Success");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Add-Payment</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  className="form-control"
                  placeholder="Title"
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="stat"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setstat(e.target.value)}
                >
                  <option value={"230"}>IsActive</option>
                  <option value={"True"}>True</option>
                  <option value={"False"}>False</option>
                </select>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  onClick={handelSubmitPayment}
                >
                  Add Payment
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Payment</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Stat</th>
                      </tr>
                    </thead>

                    {paymentList.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.stat}</td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
