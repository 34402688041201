import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

export const CreateType = () => {
  const location = useLocation();
  const propsData = location.state;

  const [cateSeven, setCateSeven] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sevenCode, setsevenCode] = useState("");
  const [sevenName, setsevenName] = useState("");
  const [sevenStat, setsevenStat] = useState("");
  const [sub, setsub] = useState("");

  useEffect(() => {
    setsevenCode(Math.floor(Math.random() * 823585) + 12805 * 5);
  }, []);

  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch(
        "https://test.eliteyez.com/admin/listCategorySeven"
      );
      setCateSeven(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const [detailes, setdetailes] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch(
        `https://test.eliteyez.com/admin/listCategoryDetail/${propsData.code}`
      );
      setdetailes(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const handelCateDetail = async () => {
    try {
      await axios.post(
        "https://test.eliteyez.com/admin/insertDataCategoryDetail",
        {
          sevenCode: sevenCode,
          sevenName: sevenName,
          sevenStat: sevenStat,
          codeCat: propsData.code,
          CatName: propsData.name,
        }
      );
      setsevenCode(Math.floor(Math.random() * 823585) + 12805 * 5);
      alert("Success - Add Type");
    } catch (err) {
      alert("The Type Cannot Be Duplicated");
    }
  };
  const handelDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteCategoryDetial/${id}`)
      .then((response) => {
        setCateSeven((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };
  return (
    <>
      <main id="main" class="main">
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-xxl-4 col-md-12">
                  <div class="card info-card sales-card">
                    <div class="filter">
                      <a class="icon" href="#" data-bs-toggle="dropdown">
                        <i class="bi bi-arrow-down-circle"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li>
                          <a class="dropdown-item" href="#">
                            Code : {propsData.code}
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Name : {propsData.name}
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Stat : {propsData.stat}
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Image :{" "}
                            <img
                              src={propsData.img}
                              height={"30px"}
                              width={"30px"}
                              alt="jupiter"
                            />{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <p></p>
                    <div class="card-body">
                      <form class="row g-3">
                        <p></p>
                        <div class="col-md-4">
                          <input
                            type="text"
                            name="sevenCode"
                            value={sevenCode}
                            readOnly
                            className="form-control"
                            placeholder="Enter Code Genarator"
                            onChange={(e) => setsevenCode(e.target.value)}
                          />
                        </div>
                        <div class="col-md-4">
                          <input
                            type="text"
                            name="sevenName"
                            className="form-control"
                            placeholder="Enter Name"
                            onChange={(e) => setsevenName(e.target.value)}
                          />
                        </div>
                        <div class="col-md-4">
                          <select
                            name="sevenStat"
                            onChange={(e) => setsevenStat(e.target.value)}
                            className="form-control"
                          >
                            <option value={"ad2cs0"}>Select Category</option>
                            {cateSeven.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </form>
                      <button
                        type="submit"
                        className="btn btn-success mt-2"
                        onClick={handelCateDetail}
                      >
                        Add Type
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">CateGoryDetail</h5>
                    <table class="table datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Type</th>
                          <th>Group</th>
                        </tr>
                      </thead>

                      {detailes.map((item, index) => {
                        const { id } = item;
                        return (
                          <tbody key={item.id}>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.sevenName}</td>
                              <td>{item.sevenStat}</td>
                              <td>{item.CatName}</td>
                              <td>
                                <img
                                  src="/img/del.png"
                                  height={"30ox"}
                                  width={"30px"}
                                  onClick={() => handelDelete(id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
