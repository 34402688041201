import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
export const AuthContext = createContext();

export const AuthcontexProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("users")) || "jupiterADR"
  );

  const login = async (inputs) => {
    const res = await axios.post(
      "https://test.eliteyez.com/loginAdmin",
      inputs
    );
    setCurrentUser(res.data);
  };

  const logout = async (inputs) => {
    await axios.post("https://test.eliteyez.com/logOutAdmin");
    setCurrentUser("jupiterADR");
  };
  useEffect(() => {
    localStorage.setItem("users", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
