import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";

export const Ticket = () => {
  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/listTicket"
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteTikect/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [users, setval1] = useState();
  const [email, setval2] = useState();
  const [descs, setval3] = useState();

  const messageType = (users, email, descs) => {
    setval1(users);
    setval2(email);
    setval3(descs);
    handleShow(true);
  };

  const [mee, setMess] = useState("");

  const insertMessage = async (arv) => {
    try {
      axios.post("https://test.eliteyez.com/ticketRegisterAll", {
        users: users,
        email: email,
        descs: mee,
      });
      handleClose(true);
      toast.success("Register Success");
    } catch (Err) {
      toast.error("Error Type Message");
    }
  };

  return (
    <>
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Ticket User</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Stat</th>
                        <th>Msg</th>
                      </tr>
                    </thead>

                    {listProduct.map((item, index) => {
                      const { id, users, email, descs } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.users}</td>
                            <td>{item.email}</td>
                            <td>{item.stat}</td>
                            <td>{item.descs}</td>
                            <td>
                              <img
                                src="/img/editA.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Approve"
                                alt=""
                                onClick={() => messageType(users, email, descs)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handelDeleteProduct(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h6 style={{ textAlign: "center" }}>Message</h6>
          <textarea
            rows={5}
            className="form-control"
            onChange={(e) => setMess(e.target.value)}
          ></textarea>
          <p></p>
          <button
            className="btn btn-success"
            placeholder="Message Type"
            onClick={() => insertMessage(mee)}
          >
            Send Message
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
