import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const Request = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [userid, setuserid] = useState("");
  const [title, settitle] = useState("");
  const [msg, setmsg] = useState("");
  const [type, settype] = useState("");
  const [uId, setUid] = useState("");
  const [dataAll, setDataAll] = useState([]);
  const [filterAll, setFilterAll] = useState(dataAll);

  const [cate, setCate] = useState([]);
  const [filterAllT, setFilterAllT] = useState([]);
  const [unitRF, setunitRF] = useState("");

  const [file, setfile] = useState("");

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        toast.warning("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("userid", currentUser.email);
        formdata.append("title", title);
        formdata.append("msg", msg);
        formdata.append("uId", uId);
        formdata.append("unitRF", unitRF);
        axios
          .post("https://test.eliteyez.com/UserFlowRequest", formdata)
          .then((res) => {
            toast.success("Your certificate has been issued.");
          });
      }
    } catch (err) {
      console.log(err);
      toast.error("Error Register");
    }
  };

  const [detailes, setdetailes] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      try {
        const resT = await fetch(
          `https://test.eliteyez.com/getListRequestAll/${currentUser.email}`
        );
        setdetailes(await resT.json());
      } catch (error) {}
    };
    getAllPaymentType();
  }, []);

  const [cateSeven, setCateSeven] = useState([]);

  const handelDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/deleteAllRequest/${id}`)
      .then((response) => {
        setCateSeven((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  useEffect(() => {
    const getAllProductsRFw = async () => {
      try {
        const res = await fetch("https://test.eliteyez.com/filterOne");
        setCate(await res.clone().json());
      } catch (e) {}
    };
    getAllProductsRFw();
  }, []);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(`https://test.eliteyez.com/filterTwo/${id}`);
      setFilterAllT(await res.json());
    } catch (err) {}
  };

  const paymentRequest = () => {
    toast.success("Pay Success");
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Services</h5>
              <form class="row g-3">
                <div class="col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="UserName"
                    value={currentUser.email}
                    readOnly
                    onChange={(e) => setuserid(e.target.value)}
                  />
                </div>
                <div class="col-md-5">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
                <div className="col col-sm-3">
                  <p>UploadFile:Zip or Rar</p>
                </div>
                <div className="col col-sm-4">
                  <input
                    type="file"
                    class="form-control"
                    required
                    id="floatingName"
                    name="file"
                    accept=".rar,.zip"
                    onChange={handleFile}
                  />
                </div>
                <div class="col-xs-12 col-sm-6 mt-3">
                  <select
                    id="selce"
                    className="form-control"
                    onChange={(e) => setUid(e.target.value)}
                    onClick={() => getItemTree(uId)}
                    name="uId"
                  >
                    <option value={"23e0w"}>Select Services</option>
                    {cate.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div>

                <div class="col-xs-12 col-sm-6 mt-3">
                  <select
                    id="selce"
                    className="form-control"
                    name="unitRF"
                    onChange={(e) => setunitRF(e.target.value)}
                  >
                    <option value={"0wss0"}>Select SubServices</option>
                    {filterAllT.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <div class="col-md-12">
                  <textarea
                    rows={"5"}
                    class="form-control"
                    placeholder="Message"
                    onChange={(e) => setmsg(e.target.value)}
                  />
                </div>
              </form>
              <p></p>
              <button className="btn btn-primary" onClick={submitRequest}>
                Send Request
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">List Request</h5>
            <table class="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>msg</th>
                  <th>Services</th>
                  <th>SubServices</th>
                  <th>stat</th>
                </tr>
              </thead>
              {detailes.map((item, index) => {
                const { id } = item;
                return (
                  <tbody key={item.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{item.msg}</td>
                      <td>{item.uId}</td>
                      <td>{item.unitRF}</td>
                      <td>{item.stat}</td>
                         <td>
                        {item.stat === "confirmed" ? (
                          <img
                            src="/img/act.png"
                            height={"30ox"}
                            width={"30px"}
                            alt=""
                            onClick={() => handelDelete(id)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            src="/img/del.png"
                            height={"30ox"}
                            width={"30px"}
                            alt=""
                            onClick={() => handelDelete(id)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
