import React, { useState, useEffect, useContext } from "react";

import axios from "axios";

export const AddCategory = () => {
  const [code, setcode] = useState("");
  const [name, setname] = useState("");
  const [descs, setdescs] = useState("");
  const [stat, setstat] = useState("");
  const [file, setfile] = useState("");

  const handleFileUpload = (e) => {
    setfile(e.target.files[0]);
  };
  useEffect(() => {
    setcode(Math.floor(Math.random() * 832015) + 10802 * 8);
  }, []);

  const handelProduct = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        alert("Please Upload Image Product");
      } else {
        const formdata = new FormData();
        formdata.append("code", code);
        formdata.append("name", name);
        formdata.append("stat", stat);
        formdata.append("descs", descs);
        formdata.append("image", file);
        formdata.append("date", new Date());

        axios
          .post("https://test.eliteyez.com/admin/categoryUp", formdata)
          .then((res) => {
            alert("Success Upload Image");
            setcode(Math.floor(Math.random() * 832015) + 10802 * 8);
          });
      }
    } catch (err) {
      console.log(err);
      alert("Error In Upload Or Name");
    }
  };

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const getAllProduct = async () => {
      const resT = await fetch(
        "https://test.eliteyez.com/admin/listCategorySeven"
      );
      setListProduct(await resT.json());
    };
    getAllProduct();
  }, []);

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteCategorySeven/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Product Row");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">AddCategory</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  name="name"
                  placeholder="name"
                  className="form-control"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="stat"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setstat(e.target.value)}
                >
                  <option value={"01"}>IsActive</option>
                  <option>True</option>
                  <option>False</option>
                </select>
              </div>
              <div class="col-md-6">
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  onChange={handleFileUpload}
                />
              </div>
              <div class="col-12">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Message"
                  style={{ padding: "0px" }}
                  onChange={(e) => setdescs(e.target.value)}
                  className="form-control"
                  name="descs"
                ></textarea>
              </div>
              <div class="text-center">
                <button className="btn btn-info" onClick={handelProduct}>
                  Add - Category
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Category</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Active</th>
                        <th scope="col">Image</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>

                    {listProduct.map((item, index) => {
                      const { id, code, name, stat } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.stat}</td>
                            <td>
                              <img
                                src={item.img}
                                width={"30px"}
                                height={"30px"}
                                alt="NoImage"
                              />
                            </td>
                            <td>
                              <img
                                src="/img/editA.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Edit"
                              />
                            </td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handelDeleteProduct(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
