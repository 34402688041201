import React from 'react'
import { CChart } from '@coreui/react-chartjs'

export const PanelRp = () => {

  const getStyle = () =>{
      
  }

  return (

   <>
   <main id="main" className="main">

<section className="section dashboard">
  <div className="row">
    {/* Left side columns */}
    <div className="col-lg-12">
      <div className="row">
        {/* Sales Card */}
        <div className="col-md-4">
          <div className="card info-card sales-card">
             <div className="card-body">
              <h5 className="card-title">
              Matches
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-check-circle" />
                </div>
                <div className="ps-3">
                  <h6>145</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Sales Card */}
        {/* Revenue Card */}
        <div className="col-xxl-4 col-md-4">
          <div className="card info-card revenue-card">
            <div className="card-body">
              <h5 className="card-title">
              Uploaded Assets
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-view-list" />
                </div>
                <div className="ps-3">
                  <h6>3,945</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="card info-card revenue-card">

            <div className="card-body">
              <h5 className="card-title">
              Potential Earnings
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-cash" />
                </div>
                <div className="ps-3">
                  <h6>$3,264</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


    <div class="card">
        <div class="card-body">
          <h5 class="card-title">Service Report</h5>

          <ul class="nav nav-tabs nav-tabs-bordered" id="borderedTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-home" type="button" role="tab" aria-controls="home" aria-selected="true">WEB PROTECTION</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false">SOCIAL MEDIA MONITORING</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#bordered-contact" type="button" role="tab" aria-controls="contact" aria-selected="false">APP MONITORING</button>
            </li>
          </ul>
          <div class="tab-content pt-2" id="borderedTabContent">
            <div class="tab-pane fade show active" id="bordered-home" role="tabpanel" aria-labelledby="home-tab">
            Secure your online presence with Eliteyez Web Protection. Register your website, logos, and brand assets for comprehensive monitoring and defense. Eliteyez is your trusted partner in safeguarding your digital assets and intellectual property, ensuring a secure and resilient online identity.
            <p></p>
            <div style={{textAlign:'center'}}>
            <div className="col-sm-8">
            <CChart
  type="polarArea"
  data={{
    labels: ['Video', 'Image', 'Document', 'Sound'],
    datasets: [
      {
        data: [11, 16, 7, 3],
        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED'],
      },
    ],
  }}
  options={{
    plugins: {
      legend: {
        labels: {
          color: getStyle('--cui-body-color'),
        }
      }
    },
    scales: {
      r: {
        grid: {
          color: getStyle('--cui-border-color'),
        },
      }
    }
  }}
/>
                  </div>
            </div>

            </div>
            <div class="tab-pane fade" id="bordered-profile" role="tabpanel" aria-labelledby="profile-tab">
            Elevate your social media game with Eliteyez Social Media Monitoring. Connect your accounts to empower Eliteyez to diligently monitor and protect your digital assets. Your online reputation is our priority, and we're here to fortify it every step of the way.
              <p></p>
            <div style={{textAlign:'center'}}>
<div className="col-sm-12">
            <CChart
  type="bar"
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#f87979',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}
  labels="months"
  options={{
    plugins: {
      legend: {
        labels: {
          color: getStyle('--cui-body-color'),
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
        },
        ticks: {
          color: getStyle('--cui-body-color'),
        },
      },
      y: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
        },
        ticks: {
          color: getStyle('--cui-body-color'),
          
        },
      },
    },
  }}
/>
   </div>
   </div>
   </div>
<div class="tab-pane fade" id="bordered-contact" role="tabpanel" aria-labelledby="contact-tab">
            Empower your app's security with Eliteyez App Monitoring. Register your app for seamless monitoring and safeguarding. Eliteyez ensures the protection of your digital assets and intellectual property, offering peace of mind for your innovation journey.
              <p></p>
            <div style={{textAlign:'center'}}>
<div className="col-sm-12">
<CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "rgba(151, 187, 205, 1)",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}
  options={{
    plugins: {
      legend: {
        labels: {
          color: getStyle('--cui-body-color'),
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
        },
        ticks: {
          color: getStyle('--cui-body-color'),
        },
      },
      y: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
        },
        ticks: {
          color: getStyle('--cui-body-color'),
        },
      },
    },
  }}
/>
  </div>
  </div>
            </div>
          </div>
        </div>
      </div>
</section>
</main>

</>
  )
}

