import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

export const Office = () => {
  const [title, settitle] = useState("");
  const [desc, setdesc] = useState("");

  const [paymentList, setPaymentList] = useState([]);
  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch("https://test.eliteyez.com/patOff");
      setPaymentList(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const handelSubmitPayment = async () => {
    try {
      await axios.post("https://test.eliteyez.com/admin/insertPat", {
        title: title,
        descs: desc,
      });
      alert("Success Add");
    } catch (err) {
      alert("Error Submit Try Again");
    }
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deletePat/${id}`)
      .then((response) => {
        setPaymentList((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete News Success");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Add</h5>
            <form class="row g-3">
              <div class="col-md-4">
                <input
                  className="form-control"
                  placeholder="Title"
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
              <div class="col-md-8">
                <input
                  className="form-control"
                  placeholder="Desc"
                  name="desc"
                  onChange={(e) => setdesc(e.target.value)}
                />
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={handelSubmitPayment}
                >
                  Add Licensing
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section dashboard">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Licensing</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Desc</th>
                      </tr>
                    </thead>

                    {paymentList.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.descs}</td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
