import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContextUser } from "../context/UserContext";

export const PanelUser = () => {
  const { currentUser } = useContext(AuthContextUser);

  const navigate = useNavigate();

  const [inputs, setInput] = useState({
    email: "",
    password: "",
  });

  const handelremovesession = () => {
    navigate("/UsersLogin");
  };

  const handelOpern = async () => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach((e) => e.addEventListener(type, listener));
      } else {
        select(el, all).addEventListener(type, listener);
      }
    };

    if (select(".toggle-sidebar-btn")) {
      on("click", ".toggle-sidebar-btn", function (e) {
        select("body").classList.toggle("toggle-sidebar");
      });
    }

    if (select(".search-bar-toggle")) {
      on("click", ".search-bar-toggle", function (e) {
        select(".search-bar").classList.toggle("search-bar-show");
      });
    }
  };

  const [counter, setcounter] = useState(0);
  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch("https://test.eliteyez.com/admin/listNews");
      setPaymentList(await resT.json());
    };
    getAllPaymentType();
  }, []);

  return (
    <>
      <body>
        <header id="header" class="header fixed-top d-flex align-items-center">
          <div class="d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="/img/logo192.png" alt="" />
              <span class="d-none d-lg-block">Eliteyez</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" onClick={handelOpern}></i>
          </div>

          <div class="search-bar">
            <form
              class="search-form d-flex align-items-center"
              method="POST"
              action="#"
            >
              <input
                type="text"
                name="query"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i class="bi bi-search"></i>
              </button>
            </form>
          </div>

          <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
              <li class="nav-item d-block d-lg-none">
                <a class="nav-link nav-icon search-bar-toggle " href="#">
                  <i class="bi bi-search"></i>
                </a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                  <i class="bi bi-bell"></i>
                  <span class="badge bg-primary badge-number"></span>
                </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li class="dropdown-header">Eliteyez News</li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li class="notification-item">
                    <i class="bi bi-exclamation-circle text-warning"></i>
                    <div>
                      {paymentList.map((item) => {
                        const { id, newsTitle, newsDesc } = item;
                        return (
                          <>
                            <h4>{item.newsTitle}</h4>
                            <p>{item.newsDesc}</p>
                          </>
                        );
                      })}
                    </div>
                  </li>
                  <li>{/*   <hr class="dropdown-divider" /> */}</li>
                </ul>
              </li>

              <li class="nav-item dropdown pe-3">
                <a
                  class="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="/img/user.png"
                    alt="Profile"
                    class="rounded-circle"
                  />
                  <span class="d-none d-md-block dropdown-toggle ps-2">
                    {currentUser.nameuser}
                  </span>
                </a>

                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li class="dropdown-header">
                    <h6>UserId : {currentUser.userid}</h6>
                    <span>Email : {currentUser.email}</span>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={handelremovesession}>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </header>

        <aside id="sidebar" class="sidebar">
          <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
              <Link to={"/UserPanel/Dasheboard"}>
                <a class="nav-link " href="">
                  <i class="bi bi-grid"></i>
                  <span>Dashboard</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UserPanel/Request"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-journal-check"></i>
                  <span>Services</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UserPanel/infringement"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-stack"></i>
                  <span>Infringement</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UserPanel/RequestComplaint"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-file-earmark-lock2-fill"></i>
                  <span>Claim</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UserPanel/ViewUserStore"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-receipt"></i>
                  <span>Payment</span>
                </a>
              </Link>
            </li>

{/*             <li class="nav-item">
              <Link to={"/UserPanel/patent"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-receipt"></i>
                  <span>Official Licensing</span>
                </a>
              </Link>
            </li> */}

            <li class="nav-item">
              <Link to={"/UserPanel/ListAll-Certificate"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-sticky"></i>
                  <span>Certificate</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UserPanel/TicketUser"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-envelope"></i>
                  <span>Support Desk</span>
                </a>
              </Link>
            </li>
            <li class="nav-item">
              {/* <Link to={"/UserPanel/ChangePassword"}> */}
              <Link to={"/UserPanel/ProfileUser"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-key-fill"></i>
                  <span>Profile</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/UsersLogin"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-dash-circle"></i>
                  <span>Logout</span>
                </a>
              </Link>
            </li>
          </ul>
        </aside>
      </body>
    </>
  );
};
