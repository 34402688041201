import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";

import { AdminLogin } from "./Login/AdminLogin";
import { Main } from "./Main/Main";
import { ProfileAdmin } from "./Main/ProfileAdmin";
import { Crawler } from "./Main/Crawler";
import { Ticket } from "./UserManager/Ticket";
import { Contact } from "./UserManager/Contact";
import { UserManagerR } from "./UserManager/UserManagerR";
import { Payment } from "./Payment/Payment";
import { TypePayment } from "./Payment/TypePayment";
import { Group } from "./Category/Group";
/* import { SubGroup } from './Category/SubGroup' */
import { Shopping } from "./Shopping/Shopping";
import { CreateType } from "./Shopping/CreateType";
/* import { CreateSelectType } from './Shopping/CreateSelectType' */
import { JupiterNews } from "./News/JupiterNews";
import { AddCategory } from "./Category/AddCategory";
import { CreateProduct } from "./Category/CreateProduct";
import { PanelRp } from "./Main/PanelRp";
import { Store } from "./Main/Store";
import { PanelUser } from "./Users/PanelUser";
import { Infringement } from "./Users/Infringement";

import { LoginUser } from "./Users/LoginUser";
import { Dasheboard } from "./Users/Dasheboard";
import { Request } from "./Users/Request";
import { StoreUser } from "./Users/StoreUser";
import { TicketUser } from "./Users/TicketUser";
import { ChangePassword } from "./Users/ChangePassword";
import { Patent } from "./Users/Patent";
import { UserRegister } from "./Users/UserRegister";
import { Certificate } from "./Users/Certificate";
import { ListCertificate } from "./Users/ListCertificate";
import { ResivedMsg } from "./Users/ResivedMsg";
import { RequestComplaint } from "./Users/RequestComplaint";
import { CertificateAdmin } from "./News/CertificateAdmin";
import { Flow } from "./News/Flow";
import { Office } from "./News/Office";
import { NewLog } from "./News/NewLog";
import { ComplateFlow } from "./News/ComplateFlow";
import { Profile } from "./Users/Profile";

import { ApiFind } from "./Main/ApiFind";

const App = () => {
  const Layout = () => {
    return (
      <div>
        <Main />
        <Outlet />
      </div>
    );
  };
  const LyoutLoing = () => {
    return (
      <div>
        <AdminLogin />
        <Outlet />
      </div>
    );
  };

  const UsersRp = () => {
    return (
      <div>
        <PanelUser />
        <Outlet />
      </div>
    );
  };

  const Loginusers = () => {
    return (
      <div>
        <LoginUser />
        <Outlet />
      </div>
    );
  };

  const RegisterToForm = () => {
    return (
      <div>
        <UserRegister />
        <Outlet />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/Admin",
      element: <LyoutLoing />,
    },

    {
      path: "/UsersLogin",
      element: <Loginusers />,
    },

    {
      path: "/UserRegister",
      element: <RegisterToForm />,
    },

    {
      path: "/UserPanel",
      element: <UsersRp />,
      children: [
        {
          path: "/UserPanel/Dasheboard",
          element: <Dasheboard />,
        },
        {
          path: "/UserPanel/Request",
          element: <Request />,
        },
        {
          path: "/UserPanel/ViewUserStore",
          element: <StoreUser />,
        },

        {
          path: "/UserPanel/TicketUser",
          element: <TicketUser />,
        },
        {
          path: "/UserPanel/ChangePassword",
          element: <ChangePassword />,
        },
        {
          path: "/UserPanel/patent",
          element: <Patent />,
        },

        {
          path: "/UserPanel/Certificate",
          element: <Certificate />,
        },
        {
          path: "/UserPanel/ListAll-Certificate",
          element: <ListCertificate />,
        },

        {
          path: "/UserPanel/RequestComplaint",
          element: <RequestComplaint />,
        },
        {
          path: "/UserPanel/ResivedMsgAll",
          element: <ResivedMsg />,
        },
        {
          path: "/UserPanel/ProfileUser",
          element: <Profile />,
        },
        {
          path: "/UserPanel/infringement",
          element: <Infringement />,
        },
        
      ],
    },

    {
      path: "/panelAdmin",
      element: <Layout />,
      children: [
        {
          path: "/panelAdmin/Profile",
          element: <ProfileAdmin />,
        },
        {
          path: "/panelAdmin/TicketList",
          element: <Ticket />,
        },
        {
          path: "/panelAdmin/userManager",
          element: <UserManagerR />,
        },
        {
          path: "/panelAdmin/paymentGateway",
          element: <Payment />,
        },
        {
          path: "/panelAdmin/TypePayment",
          element: <TypePayment />,
        },

        {
          path: "/panelAdmin/GroupAll",
          element: <Group />,
        },
        {
          path: "/panelAdmin/ManageServices",
          element: <Shopping />,
        },
        {
          path: "/panelAdmin/createTypeSh",
          element: <CreateType />,
        },
        {
          path: "/panelAdmin/NewsJupiter",
          element: <JupiterNews />,
        },
        {
          path: "/panelAdmin/Add-category",
          element: <AddCategory />,
        },
        {
          path: "/panelAdmin/Create-Product",
          element: <CreateProduct />,
        },
        {
          path: "/panelAdmin/PanelRp",
          element: <PanelRp />,
        },
        {
          path: "/panelAdmin/apiSearch",
          element: <ApiFind />,
        },
        {
          path: "/panelAdmin/CreateStore",
          element: <Store />,
        },

        {
          path: "/panelAdmin/Contact",
          element: <Contact />,
        },
        {
          path: "/panelAdmin/Flow",
          element: <Flow />,
        },

        {
          path: "/panelAdmin/Official",
          element: <Office />,
        },

        {
          path: "/panelAdmin/Log",
          element: <NewLog />,
        },

        {
          path: "/panelAdmin/ComplateFlow",
          element: <ComplateFlow />,
        },

        {
          path: "/panelAdmin/CertificateAdmin",
          element: <CertificateAdmin />,
        },
        {
          path: "/panelAdmin/CrawlerWebsite",
          element: <Crawler />,
        },
      ],
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
