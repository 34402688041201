import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Main = () => {
  // const {currentUser} = useContext(AuthContext);

  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const [inputs, setInput] = useState({
    users: "",
    password: "",
  });

  const handelremovesession = () => {
    logout(inputs);
    navigate("/");
  };

  const handelOpern = async () => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach((e) => e.addEventListener(type, listener));
      } else {
        select(el, all).addEventListener(type, listener);
      }
    };

    if (select(".toggle-sidebar-btn")) {
      on("click", ".toggle-sidebar-btn", function (e) {
        select("body").classList.toggle("toggle-sidebar");
      });
    }

    if (select(".search-bar-toggle")) {
      on("click", ".search-bar-toggle", function (e) {
        select(".search-bar").classList.toggle("search-bar-show");
      });
    }
  };

  //if (currentUser.users == null){

  // }else{

  return (
    <>
      <body>
        <header id="header" class="header fixed-top d-flex align-items-center">
          <div class="d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="/img/logo192.png" alt="" />
              <span class="d-none d-lg-block">Eliteyez - Admin</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" onClick={handelOpern}></i>
          </div>

          <div class="search-bar">
            <form
              class="search-form d-flex align-items-center"
              method="POST"
              action="#"
            >
              <input
                type="text"
                name="query"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i class="bi bi-search"></i>
              </button>
            </form>
          </div>

          <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
              <li class="nav-item d-block d-lg-none">
                <a class="nav-link nav-icon search-bar-toggle " href="#">
                  <i class="bi bi-search"></i>
                </a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                  <i class="bi bi-bell"></i>
                  <span class="badge bg-primary badge-number">1</span>
                </a>

                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li class="dropdown-header">
                    Eliteyez
                    <a href="#">
                      <span class="badge rounded-pill bg-primary p-2 ms-2">
                        View all
                      </span>
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li class="notification-item">
                    <i class="bi bi-exclamation-circle text-warning"></i>
                    <div>
                      <h4>Admin</h4>
                      <p>Eliteyez</p>
                      <p>All</p>
                    </div>
                  </li>
                  <li>{/*   <hr class="dropdown-divider" /> */}</li>
                </ul>
              </li>

              <li class="nav-item dropdown" />

              <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                <i class="bi bi-chat-left-text"></i>
                <span class="badge bg-success badge-number">1</span>
              </a>

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li class="dropdown-header">
                  Eliteyez
                  <a href="#">
                    <span class="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                {/*  <li>
          <hr class="dropdown-divider" />
        </li> */}
              </ul>

              <li class="nav-item dropdown pe-3">
                <a
                  class="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="/img/user.png"
                    alt="Profile"
                    class="rounded-circle"
                  />
                  <span class="d-none d-md-block dropdown-toggle ps-2">
                    Admin
                  </span>
                </a>

                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li class="dropdown-header">
                    <h6>User-Admin</h6>
                    <span>Admin</span>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      class="dropdown-item d-flex align-items-center"
                      href="users-profile.html"
                    >
                      <i class="bi bi-person"></i>
                      <Link to={"/panelAdmin/profile"}>
                        <span>My Profile</span>
                      </Link>
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      class="dropdown-item d-flex align-items-center"
                      href="users-profile.html"
                    >
                      <i class="bi bi-gear"></i>
                      <Link to={"/panelAdmin/profile"}>
                        <span>Account Settings</span>
                      </Link>
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={handelremovesession}>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </header>

        <aside id="sidebar" class="sidebar">
          <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
              <Link to={"/PanelAdmin/PanelRp"}>
                <a class="nav-link " href="">
                  <i class="bi bi-grid"></i>
                  <span>Dashboard</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <a
                class="nav-link collapsed"
                data-bs-target="#forms-nav"
                data-bs-toggle="collapse"
                href="#"
              >
           <i class="bi bi-gem"></i>
                <span>Services</span>
              </a>
              <ul
                id="forms-nav"
                class="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={"/panelAdmin/GroupAll"}>
                    <a href="#">
                      <i class="bi bi-circle"></i>
                      <span>CreateServices</span>
                    </a>
                  </Link>
                </li>

                <li>
                  <Link to={"/panelAdmin/ManageServices"}>
                    <a href="f#">
                      <i class="bi bi-circle"></i>
                      <span>Manage Services</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a
                class="nav-link collapsed"
                data-bs-target="#tables-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <i class="bi bi-layout-text-window-reverse"></i>
                <span>Payment</span>
                <i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="tables-nav"
                class="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={"/panelAdmin/paymentGateway"}>
                    <a href="#">
                      <i class="bi bi-circle"></i>
                      <span>CreateGatway</span>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to={"/panelAdmin/TypePayment"}>
                    <a href="#">
                      <i class="bi bi-circle"></i>
                      <span>PaymentType</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>


<li class="nav-item">
</li>

      <li class="nav-item">
              <a
                class="nav-link collapsed"
                data-bs-target="#tables-navR"
                data-bs-toggle="collapse"
                href="#"
              >
                <i class="bi bi-cart"></i>
                <span>Store</span>
                <i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="tables-navR"
                class="nav-content collapse"
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={"/panelAdmin/CreateStore"}>
                    <a href="#">
                      <i class="bi bi-circle"></i>
                      <span>CreateStore</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </li>

         <li class="nav-item">
              <Link to={"/panelAdmin/Flow"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-card-text"></i>
                  <span>Requests received</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/CrawlerWebsite"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-journals"></i>
                  <span>Crawler</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/apiSearch"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-journals"></i>
                  <span>Image Finde</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/ComplateFlow"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-receipt"></i>
                  <span>Complaint received</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/CertificateAdmin"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-credit-card-2-front"></i>
                  <span>Certificate Create</span>
                </a>
              </Link>
            </li>


            <li class="nav-item">
              <Link to={"/panelAdmin/userManager"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-person-gear"></i>
                  <span>UserManager</span>
                </a>
              </Link>
            </li>


            <li class="nav-item">
              <Link to={"/panelAdmin/Official"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-person-gear"></i>
                  <span>Official Licensing</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/Log"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-person-gear"></i>
                  <span>Report-Log</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/TicketList"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-ticket"></i>
                  <span>Ticket</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/NewsJupiter"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-envelope"></i>
                  <span>News</span>
                </a>
              </Link>
            </li>

            <li class="nav-item">
              <Link to={"/panelAdmin/Contact"}>
                <a class="nav-link collapsed" href="pages-contact.html">
                  <i class="bi bi-telephone"></i>
                  <span>Contact</span>
                </a>
              </Link>
            </li>
          </ul>
        </aside>
      </body>
    </>
  );
};
// }
