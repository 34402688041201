import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const CreateProduct = () => {
  const location = useLocation();
  const cateOption = location.state;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showIM, setShowIM] = useState(false);
  const handleCloseIM = () => setShowIM(false);
  const handleShowIM = () => setShowIM(true);

  const [token, setToken] = useState("");
  const [file, setfile] = useState("");
  const [nameR, setnameR] = useState("");
  const [sdesc, setsdesc] = useState("");
  const [ldesc, setldesc] = useState("");
  const [price, setprice] = useState("");
  const [sprice, setsprice] = useState("");
  const [discount, setdiscount] = useState("");
  const [stprice, setstprice] = useState("");
  const [link, setlink] = useState("");
  const [expirtime, setexpirtime] = useState("");
  const [pricestep, setpricestep] = useState("");
  const [advcode, setadvcode] = useState("");
  const [stock, setstock] = useState("");
  const [owner, setowner] = useState("");
  const [comision, setcomision] = useState("");
  const [shvisable, setshvisable] = useState("");
  const [statR, setstatR] = useState("");
  const [sell, setsell] = useState("");
  const [selluser, setselluser] = useState("");
  const [action, setaction] = useState("");
  const [category, setcategory] = useState("");
  const [content, setcontent] = useState("");
  const [nameN, setnameN] = useState("");
  const [uId, setUid] = useState("");
  const [uIde, setUide] = useState("");
  const [fileTwo, setfileTwo] = useState("");
  const [codeR, setcodeR] = useState("");

  const handleFileUpload = (e) => {
    setfile(e.target.files[0]);
  };
  const handleFileUploads = (e) => {
    setfileTwo(e.target.files[0]);
  };

  useEffect(() => {
    setToken(Math.floor(Math.random() * 5352085) + 83500 * 3);
  }, []);

  const handelProduct = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        alert("Please Upload Image ");
      } else {
        const formdata = new FormData();
        formdata.append("token", token);
        formdata.append("image", file);
        formdata.append("nameR", nameR);
        formdata.append("sdesc", sdesc);
        formdata.append("ldesc", ldesc);
        formdata.append("price", price);
        formdata.append("sprice", sprice);
        formdata.append("discount", discount);
        formdata.append("stprice", stprice);
        formdata.append("link", link);
        formdata.append("expirtime", expirtime);
        formdata.append("pricestep", pricestep);
        formdata.append("advcode", advcode);
        formdata.append("stock", stock);
        formdata.append("owner", owner);
        formdata.append("comision", comision);
        formdata.append("shvisable", shvisable);
        formdata.append("statR", statR);
        formdata.append("sell", sell);
        formdata.append("selluser", selluser);
        formdata.append("action", action);
        formdata.append("category", category);
        formdata.append("content", content);
        formdata.append("nameN", nameN);
        formdata.append("uId", uId);
        formdata.append("uIde", uIde);
        formdata.append("codeR", codeR);

        axios
          .post(
            "https://test.eliteyez.com/admin/insertingCateGoryOption",
            formdata
          )
          .then((res) => {
            // alert("Success Upload Image")
          });
      }

      updateTwoImage(token);
    } catch (err) {
      console.log(err);
      alert("Error In Upload Or Name");
    }
  };

  const updateTwoImage = async (token) => {
    if (!fileTwo) {
      alert("Error Select Image");
    } else {
      alert("Upload Image Success");
      const formdata = new FormData();
      formdata.append("image", fileTwo);
      await axios
        .put(
          `https://test.eliteyez.com/admin/updateVategoryT/${token}`,
          formdata
        )
        .then((res) => {});
    }
  };

  const [group, setGroup] = useState([]);
  const [subGroup, setSubGroup] = useState([]);
  const [cateSeven, setCateSeven] = useState([]);

  useEffect(() => {
    try {
      const getGroup = async () => {
        const resT = await fetch(
          "https://test.eliteyez.com/admin/ListCategoryActive"
        );
        setGroup(await resT.json());
      };
      getGroup();
    } catch (err) {}
  }, []);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://test.eliteyez.com/admin/SubGroupListFilterU/${id}`
      );
      setSubGroup(await res.json());
    } catch (err) {}
  };

  useEffect(() => {
    try {
      const getAllPaymentType = async () => {
        const resT = await fetch(
          "https://test.eliteyez.com/admin/listCategorySeven"
        );
        setCateSeven(await resT.json());
      };
      getAllPaymentType();
    } catch (err) {}
  }, []);

  const [payt, setpayt] = useState([]);
  const [payment, setpayment] = useState(payt);

  useEffect(() => {
    try {
      const PaymentAllType = async () => {
        const resT = await fetch("https://test.eliteyez.com/admin/PaymentType");
        setpayt(await resT.clone().json());
        setpayment(await resT.json());
      };
      PaymentAllType();
    } catch (err) {}
  }, []);

  const showHandelPop = () => {
    handleShowIM(true);
  };

  /* const getText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html")
  return doc.body.textContent
} */

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">CreateProduct</h5>

            <form class="row g-3">
              <div class="col-md-4">
                <select
                  id="selce"
                  className="form-control"
                  onChange={(e) => setUid(e.target.value)}
                  onClick={() => getItemTree(uId)}
                  name="uId"
                >
                  <option value={"ad2cs0"}>Select Group</option>
                  {group.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-4">
                <select
                  id="selce"
                  className="form-control"
                  onChange={(e) => setUide(e.target.value)}
                  name="uIde"
                >
                  <option value={"ad2cs0"}>Select Label</option>
                  {subGroup.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-4">
                <select
                  name="nameN"
                  onChange={(e) => setnameN(e.target.value)}
                  className="form-control"
                >
                  <option value={"ad2cs0"}>Select Category</option>
                  {cateSeven.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>

              {nameN === "Token" || nameN === "Diamond" ? (
                <>
                  <div class="col-md-3">
                    <p>Name</p>
                    <input
                      type="text"
                      className="form-control col col-sm-3"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>

                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>

                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>

                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>

                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>

                  <div class="col-md-3">
                    <p>ADV code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ADV code"
                      name="advcode"
                      onChange={(e) => setadvcode(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>Stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "Vip" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>short description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="short description"
                      name="sdesc"
                      onChange={(e) => setsdesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "Avatar" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stock</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="stock"
                      name="stock"
                      onChange={(e) => setstock(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>owner</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="owner"
                      name="owner"
                      onChange={(e) => setowner(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>comision</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="comision"
                      name="comision"
                      onChange={(e) => setcomision(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>ready for sell</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ready for sell"
                      name="sell"
                      onChange={(e) => setsell(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>sell by user</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="sell by user"
                      name="selluser"
                      onChange={(e) => setselluser(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}
              {nameN === "ChatPack" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>short description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="short description"
                      name="sdesc"
                      onChange={(e) => setsdesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p></p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "Emoji" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>short description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="short description"
                      name="sdesc"
                      onChange={(e) => setsdesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "GifCard" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>link</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="link"
                      name="link"
                      onChange={(e) => setlink(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stock</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="stock"
                      name="stock"
                      onChange={(e) => setstock(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>owner</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="owner"
                      name="owner"
                      onChange={(e) => setowner(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>comision</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="comision"
                      name="comision"
                      onChange={(e) => setcomision(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>ready for sell</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ready for sell"
                      name="sell"
                      onChange={(e) => setsell(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>sell by user</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="sell by user"
                      name="selluser"
                      onChange={(e) => setselluser(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "SupriseBox" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>ADV code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ADV code"
                      name="advcode"
                      onChange={(e) => setadvcode(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "FreeReward" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>ADV code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ADV code"
                      name="advcode"
                      onChange={(e) => setadvcode(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>action</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="action"
                      name="action"
                      onChange={(e) => setaction(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "DigitalProducts" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>short description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="short description"
                      name="sdesc"
                      onChange={(e) => setsdesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>
                      long description{" "}
                      <img
                        src="/img/edit.png"
                        width={"25px"}
                        height={"25px"}
                        style={{ cursor: "pointer" }}
                        onClick={showHandelPop}
                      />
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="long description"
                      name="ldesc"
                      onChange={(e) => setldesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>link</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="link"
                      name="link"
                      onChange={(e) => setlink(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stock</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="stock"
                      name="stock"
                      onChange={(e) => setstock(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>owner</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="owner"
                      name="owner"
                      onChange={(e) => setowner(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>comision</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="comision"
                      name="comision"
                      onChange={(e) => setcomision(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>ready for sell</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ready for sell"
                      name="sell"
                      onChange={(e) => setsell(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>sell by user</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="sell by user"
                      name="selluser"
                      onChange={(e) => setselluser(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>content</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="content"
                      name="content"
                      onChange={(e) => setcontent(e.target.value)}
                    />
                  </div>
                </>
              ) : null}

              {nameN === "PhusicalProducts" ? (
                <>
                  <div class="col-md-3">
                    <p>name</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="nameR"
                      onChange={(e) => setnameR(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>short description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="short description"
                      name="sdesc"
                      onChange={(e) => setsdesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>long description</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="long description"
                      name="ldesc"
                      onChange={(e) => setldesc(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>category</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="category"
                      readOnly
                      value={nameN}
                      name="category"
                      onChange={(e) => setcategory(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price"
                      name="sprice"
                      onChange={(e) => setsprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>discount code</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="discount code"
                      name="discount"
                      onChange={(e) => setdiscount(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>special price time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="special price time"
                      name="stprice"
                      onChange={(e) => setstprice(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>link</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="link"
                      name="link"
                      onChange={(e) => setlink(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>expire time</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="expire time"
                      name="expirtime"
                      onChange={(e) => setexpirtime(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>price step</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="price step"
                      name="pricestep"
                      onChange={(e) => setpricestep(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stock</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="stock"
                      name="stock"
                      onChange={(e) => setstock(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>owner</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="owner"
                      name="owner"
                      onChange={(e) => setowner(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>comision</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="comision"
                      name="comision"
                      onChange={(e) => setcomision(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>show / hide</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="show / hide"
                      name="shvisable"
                      onChange={(e) => setshvisable(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>stat</p>
                    <select
                      name="statR"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstatR(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>True</option>
                      <option>False</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <p>ready for sell</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ready for sell"
                      name="sell"
                      onChange={(e) => setsell(e.target.value)}
                    />
                  </div>
                  <div class="col-md-3">
                    <p>sell by user</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="sell by user"
                      name="selluser"
                      onChange={(e) => setselluser(e.target.value)}
                    />
                  </div>
                </>
              ) : null}
              <div class="col-md-4">
                <p>Select Payment</p>
                <select
                  id="selce"
                  className="form-control"
                  onChange={(e) => setcodeR(e.target.value)}
                  name="codeR"
                >
                  <option value={"ad2cs0"}>Select PaymentType</option>
                  {payment.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-4">
                <p>Image(Small)</p>
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  onChange={handleFileUpload}
                />
              </div>
              <div class="col-md-4">
                <p>Image(Large)</p>
                <input
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFileUploads}
                />
              </div>
              <div class="text-center">
                <button
                  className="btn btn-info mb-2 ml-2"
                  onClick={handelProduct}
                >
                  Add - Product
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>

      <Modal
        show={showIM}
        onHide={handleCloseIM}
        className="mt-2"
        centered
        size="lg"
      >
        <Modal.Header>Editor</Modal.Header>
        <Modal.Body>
          <div className="editorContainre">
            <ReactQuill
              className="editor"
              theme="snow"
              name="ldesc"
              onChange={setldesc}
            />
          </div>
          <hr />
          <div style={{ textAlign: "center" }}>
            <button className="btn btn-success">Update</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
