import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

export const NewLog = () => {
  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get("https://test.eliteyez.com/admin/Log");
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteLogs/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete  Success");
      });
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <table class="table datatable">
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
                <th>Date</th>
                <th>IpAddress</th>
                <th>Country</th>
                <th>latx</th>
                <th>laty</th>
                <th></th>
              </tr>
            </thead>
            {listProduct.map((item, index) => {
              const { id } = item;
              return (
                <tbody key={item.id}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.date}</td>
                    <td>{item.ip}</td>
                    <td>{item.stat}</td>
                    <td>{item.latx}</td>
                    <td>{item.laty}</td>
                    <td>
                      <img
                        src="/img/del.png"
                        width={"30px"}
                        height={"30px"}
                        style={{ cursor: "pointer" }}
                        title="Delete"
                        onClick={() => handleDelete(id)}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </section>
    </main>
  );
};
