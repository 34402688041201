import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const TicketUser = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [users, setusers] = useState("");
  const [descs, setdescs] = useState("");
  const [email, setemail] = useState("");

  const submitRequest = () => {
    try {
      axios.post("https://test.eliteyez.com/ticketRegisterAll", {
        users: users,
        descs: descs,
        email: currentUser.email,
      });
      toast.success("Request Send - Success");
    } catch (Err) {
      toast.error("Error Register");
    }
  };

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        `https://test.eliteyez.com/admin/listTicketUser/${currentUser.email}`
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  /* const [detailes, setdetailes] = useState([]);

useEffect(() => {
  const getAllPaymentType = async () => {
    const resT = await fetch(
      `https://test.eliteyez.com/admin/listTicketUser/${currentUser.email}`
    );
    setdetailes(await resT.json());
  };
  getAllPaymentType();
}, []); */

  const [cateSeven, setCateSeven] = useState([]);

  const handelDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteTikect/${id}`)
      .then((response) => {
        setCateSeven((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Request Ticket</h5>
              <form class="row g-3">
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="UserName"
                    value={currentUser.email}
                    readOnly
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <div class="col-md-6">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Title"
                    onChange={(e) => setusers(e.target.value)}
                  />
                </div>
                <div class="col-md-12">
                  <textarea
                    rows={"5"}
                    class="form-control"
                    placeholder="Message"
                    onChange={(e) => setdescs(e.target.value)}
                  />
                </div>
              </form>
              <p></p>
              <button className="btn btn-primary" onClick={submitRequest}>
                Send Request
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">List Ticket</h5>
            <table class="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {listProduct.map((item, index) => {
                const { id } = item;
                return (
                  <tbody key={item.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.users}</td>
                      <td>{item.descs}</td>
                      <td>
                        <img
                          src="/img/del.png"
                          height={"30ox"}
                          width={"30px"}
                          onClick={() => handelDelete(id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
