import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

export const StoreUser = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [detailes, setdetailes] = useState([]);

  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch(
        `https://test.eliteyez.com/getListRequestAll/${currentUser.email}`
      );
      setdetailes(await resT.json());
    };
    getAllPaymentType();
  }, []);

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/getAllCategoryStore"
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handelPay = () => {
    toast.success("Payment Success !");
  };

  const [paymentList, setPaymentList] = useState([]);
  useEffect(() => {
    const getAllPaymentType = async () => {
      const resT = await fetch("https://test.eliteyez.com/admin/PaymentType");
      setPaymentList(await resT.json());
    };
    getAllPaymentType();
  }, []);

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Official Licensing</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">List Official Licensing</h5>
            <table class="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Code</th>
                  <th>Company</th>
                  <th>Price</th>
                  <th>Msg</th>
                  <th>Payment</th>
                  <th>Image</th>
                  <th>Payment</th>
                </tr>
              </thead>

              {listProduct.map((item, index) => {
                const { id, users, email, descs } = item;
                return (
                  <tbody key={item.id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.code}</td>
                      <td>{item.company}</td>
                      <td>${item.price}</td>
                      <td>{item.msg}</td>
                      <td>
                        <select
                          id="selce"
                          className="form-control"
                          name="unitRF"
                          /* onChange={(e) => setunitRF(e.target.value)} */
                        >
                          <option value={"0wss0"}>SelectPay</option>
                          {paymentList.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <img src={item.img} height={"30px"} />{" "}
                      </td>
                      <td>
                        <img
                          src="/img/paya.png"
                          height={"30px"}
                          style={{ cursor: "pointer" }}
                          onClick={handelPay}
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
