import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
export const AuthContextUser = createContext();

export const AuthcontexProviderUser = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("usersCS")) || "ELTECXE"
  );

  const login = async (inputs) => {
    const res = await axios.post("https://test.eliteyez.com/userLogin", inputs);
    setCurrentUser(res.data);
  };

  const logout = async (inputs) => {
    await axios.post("https://test.eliteyez.com/logOutAdmin");
    setCurrentUser("ELTECXE");
  };
  useEffect(() => {
    localStorage.setItem("usersCS", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContextUser.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContextUser.Provider>
  );
};
