import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SubGroup from "./SubGroup";

export const Group = () => {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [stat, setStat] = useState("");
  const [descs, setDescs] = useState("");
  const [file, setfile] = useState("");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setCode(Math.floor(Math.random() * 965225825) + 15860 * 5);
  }, []);

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        alert("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("code", code);
        formdata.append("name", name);
        formdata.append("stat", stat);
        formdata.append("image", file);
        formdata.append("descs", descs);
        formdata.append("date", new Date());
        axios
          .post("https://test.eliteyez.com/admin/category", formdata)
          .then((res) => {
            alert("Success Upload Image");
            setCode(Math.floor(Math.random() * 965225825) + 15860 * 5);
          });
      }
    } catch (err) {
      console.log(err);
      alert("Error Upload Image");
    }
  };

  const [dataAll, setDataAll] = useState([]);
  const [filterData, setFilterData] = useState(dataAll);
  useEffect(() => {
    const axiosTest = async () => {
      let response = await fetch(
        "https://test.eliteyez.com/admin/ListCategoryActive"
      );
      setDataAll(await response.clone().json());
      setFilterData(await response.json());
    };
    axiosTest();
  }, []);

  const handleupdateTrue = async (id) => {
    try {
      await axios
        .put(`https://test.eliteyez.com/admin/CategoryUpdateTrue/${id}`, {
          stat: "False",
        })
        .then(() => {
          alert("Success Update");
        });
    } catch (err) {}
  };

  const handleupdateFalse = async (id) => {
    try {
      await axios
        .put(`https://test.eliteyez.com/admin/CategoryUpdateFalse/${id}`, {
          stat: "True",
        })
        .then(() => {
          alert("Success Update");
        });
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/CategoryDelete/${id}`)
      .then((response) => {
        setDataAll((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Success");
      });
  };

  return (
    <>
      <main id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">CreatService</h5>
            <form class="row g-3">
              <div class="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="EnterName"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="col-md-6">
                <select
                  name="stat"
                  id="selce"
                  className="form-control"
                  onChange={(e) => setStat(e.target.value)}
                >
                  <option value={"01"}>IsActive</option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
              <div class="col-md-6">
                <input
                  type="file"
                  name="file"
                  onChange={handleFile}
                  className="form-control"
                />
              </div>
              <div class="col-12">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Message"
                  style={{ padding: "0px" }}
                  className="form-control"
                  name="descs"
                  onChange={(e) => setDescs(e.target.value)}
                ></textarea>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  onClick={handlePayment}
                >
                  Add Service
                </button>
              </div>
            </form>
          </div>
        </div>
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Services</h5>
                  <table class="table datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Stat</th>
                        <th></th>
                      </tr>
                    </thead>

                    {filterData.map((item, index) => {
                      const { id } = item;
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>
                              <img
                                src={item.img}
                                width={"30px"}
                                height={"30px"}
                                alt="NoImage"
                              />
                            </td>
                            <td>{item.stat}</td>
                            <td>
                              <input type="checkbox" />
                            </td>
                            <td>
                              <img
                                src="/img/true.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Stat False"
                                onClick={() => handleupdateTrue(id)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/false.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Stat True"
                                onClick={() => handleupdateFalse(id)}
                              />
                            </td>
                            <td>
                              <img
                                src="/img/del.png"
                                width={"30px"}
                                height={"30px"}
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => handleDelete(id)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
            <h3>Services</h3>
          </div>
        </section>
      </main>

      <SubGroup />
    </>
  );
};
export default Group;
