import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Barcode from "react-barcode";
import { Link, useNavigate, useLocation } from "react-router-dom";

export const Certificate = () => {
  const { currentUser } = useContext(AuthContextUser);

  const location = useLocation();
  const propsData = location.state;

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        `https://test.eliteyez.com/admin/listUserManagerTwoAll/${propsData.id}`
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 5);
      pdf.save("Certificate.pdf");
    });
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div
              class="card-body"
              style={{ textAlign: "center", border: "double" }}
              id="divToPrint"
            >
              <p></p>
              <img src="/img/logo192.png" height={"100px"} />
              <p></p>
              {listProduct.map((item, index) => {
                const { id } = item;
                return (
                  <>
                    <h4>{item.company}</h4>
                    <h3>{item.title}</h3>
                    <p>{item.date}</p>
                    <h4>{item.exporter}</h4>
                    <h2>{item.username}</h2>
                    <p>{item.msg}</p>
                    <p>
                      <img src={item.img} height={"100px"} />
                    </p>
                    <Barcode value={item.barcode} />
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="mb5">
          <button className="btn btn-primary" onClick={printDocument}>
            Download-Pdf
          </button>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
