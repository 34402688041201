import React, { useContext, useState, useEffect } from "react";
import { AuthContextUser } from "../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

export const Store = () => {
  const { currentUser } = useContext(AuthContextUser);

  const [code, setcode] = useState("");
  const [company, setcompany] = useState("");
  const [price, setprice] = useState("");
  const [date, setdate] = useState("");
  const [stat, setstat] = useState("");
  const [msg, setmsg] = useState("");

  useEffect(() => {
    setcode(Math.floor(Math.random() * 82580) + 58200 * 3);
  }, []);

  const [file, setfile] = useState("");

  const handleFile = (e) => {
    setfile(e.target.files[0]);
  };

  const insertcirt = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        toast.warning("Please Upload Image !");
      } else {
        const formdata = new FormData();
        formdata.append("image", file);
        formdata.append("code", code);
        formdata.append("company", company);
        formdata.append("price", price);
        formdata.append("date", date);
        formdata.append("stat", stat);
        formdata.append("msg", msg);
        axios
          .post("https://test.eliteyez.com/admin/Store", formdata)
          .then((res) => {
            toast.success("Create Store - Success");
          });
      }
    } catch (err) {
      console.log(err);
      toast.error("Create Store - Error");
    }
  };

  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const axiosTest = async () => {
      let response = await axios.get(
        "https://test.eliteyez.com/admin/getAllCategoryStore"
      );
      setListProduct(response.data);
    };
    axiosTest();
  }, []);

  const handelDeleteProduct = async (id) => {
    axios
      .delete(`https://test.eliteyez.com/admin/deleteCategroyStore/${id}`)
      .then((response) => {
        setListProduct((values) => {
          return values.filter((item) => item.id !== id);
        });
        alert("Delete Row Success");
      });
  };

  return (
    <main id="main" class="main">
      <section class="section">
        <div class="row">
          <div class="card">
            <div class="card-body" style={{ textAlign: "center" }}>
              <p></p>
              <img src="/img/logo192.png" height={"100px"} />
              <p></p>
              <div className="container">
                <div className="row">
                  <div className="col col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      onChange={(e) => setcompany(e.target.value)}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Price"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setdate(e.target.value)}
                    />
                  </div>
                  <p></p>
                  <div className="col col-sm-4">
                    <input
                      type="file"
                      class="form-control"
                      required
                      id="floatingName"
                      name="file"
                      onChange={handleFile}
                    />
                  </div>
                  <div class="col-md-4">
                    <select
                      name="stat"
                      id="selce"
                      className="form-control"
                      onChange={(e) => setstat(e.target.value)}
                    >
                      <option value={"01"}>IsActive</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </select>
                  </div>
                  <div className="col col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={code}
                      onChange={(e) => setcode(e.target.value)}
                    />
                  </div>
                  <p></p>
                  <div className="col col-sm-12">
                    <textarea
                      rows={"5"}
                      className="form-control"
                      placeholder="Message"
                      onChange={(e) => setmsg(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr />
              <table class="table datatable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Company</th>
                    <th>Price</th>
                    <th>Msg</th>
                    <th>Image</th>
                    <th>Delete</th>
                  </tr>
                </thead>

                {listProduct.map((item, index) => {
                  const { id, users, email, descs } = item;
                  return (
                    <tbody key={item.id}>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.code}</td>
                        <td>{item.company}</td>
                        <td>{item.price}</td>
                        <td>{item.msg}</td>
                        <td>
                          <img src={item.img} height={"30px"} />{" "}
                        </td>
                        <td>
                          <img
                            src="/img/del.png"
                            width={"30px"}
                            height={"30px"}
                            style={{ cursor: "pointer" }}
                            title="Delete"
                            onClick={() => handelDeleteProduct(id)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              <p></p>
              <button className="btn btn-primary" onClick={insertcirt}>
                Create
              </button>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
    </main>
  );
};
